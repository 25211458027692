import {observer} from "mobx-react-lite";
import {useContext} from "react";
import {LppStoreContext} from "../App";
import {Marker, Popup} from "react-leaflet";
import {DivIcon, LatLngExpression} from "leaflet";

export const BusLayer = observer(() => {
    const store = useContext(LppStoreContext);
    return <>
        {store.busses.map((b) => {
            return <Marker
                icon={new DivIcon({
                    html: `<div class="bus-marker"><div style="rotate: ${b.rotation + 45}deg; background-color: ${store.groupColor(b.group)}" class="bus-marker-bg"/><div style="rotate: -${b.rotation + 45}deg" class="bus-marker-label">${b.group}</div></div>`,
                    iconSize: [16, 16],
                    className: 'div-icon'
                })}
                key={'bus-marker-' + b.id}
                position={b.location as LatLngExpression}
            >
                <Popup>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: 'column'
                    }}>
                        <b>{b.group} ({b.registrationPlate})</b>
                    </div>
                </Popup>
            </Marker>
        })}
    </>;
});