import {makeAutoObservable} from "mobx";
import {SocketApi} from "proto_socket_typescript";

export class PoiStore {
    api: SocketApi;

    constructor(api: SocketApi) {
        this.api = api;
        makeAutoObservable(this);
    }
}
