import {observer} from "mobx-react-lite";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Polyline,
    Polygon,
    GeoJSON,
    CircleMarker,
    Circle,
    useMapEvent, useMap
} from 'react-leaflet'
import {DivIcon, LatLngExpression, LineCapShape, LineJoinShape} from "leaflet";
import {MdDirections, MdDirectionsBus, MdDirectionsTransit} from "react-icons/md";
import styled from "styled-components";
import {useContext, useEffect, useState} from "react";
import {LppStoreContext} from "../App";
import {LppTypes} from "../lpp_types";
import {lpp_maps} from "../proto/compiled";
import IconProdajnoMesto from '../assets/icons/lpp_prodajno_mesto.png';
import IconUrbanomat from '../assets/icons/lpp_urbanomat.png';
import IconObmocja from '../assets/icons/obmocja.svg';
import IconKlinko from '../assets/icons/klinko.png';
import IconPPlusR from '../assets/icons/lpp_p_plus_r.png';
import IconPokopalisce from '../assets/icons/pokopalisce.png';
import IconPosta from '../assets/icons/posta.png';
import IconPetrol from '../assets/icons/petrol.png';
import Icon300m from '../assets/icons/area_300m.png';
import Icon500m from '../assets/icons/area_500m.png';
import {StyledProps} from "../theme";
import useWave from "use-wave";
import {BusData} from "../data/bus_data";
import {BusLayer} from "./bus_layer";

const LjCenter: LatLngExpression = [46.053830, 14.508713];

const MapEventHandlers = () => {
    const store = useContext(LppStoreContext);
    const [moved, setMoved] = useState(false);
    const map = useMap();
    useEffect(() => {
        const position = store.currentPosition;
        if (!moved && position) {
            map.setView(position, 15);
        }
    }, [map, moved, store.currentPosition]);
    const mapMoveEnd = useMapEvent('moveend', (e) => {
        if (!moved) setMoved(true);
    });
    return <></>;
}

export const LppMap = observer(() => {
    const store = useContext(LppStoreContext);
    const layers: any[] = [];

    const wave = useWave();
    store.visibleLayers.forEach((layerId) => addLayer(layers, wave, store.layerData.get(layerId)));
    layers.push(<BusLayer key={'bus-layer'}/>)
    if (store.currentPosition) layers.push(<Marker
        icon={new DivIcon({
            html: `<div class="my-location-marker"/>`,
            iconSize: [12, 12],
            className: 'div-icon'
        })}
        key={'my-location'}
        position={store.currentPosition}
    />)
    return <MapContainer center={LjCenter} zoom={13} zoomControl={false}>
        <MapEventHandlers/>
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {layers}
    </MapContainer>;
});

const DirectionsButton = styled.div`
  margin-top: 8px;
  width: 100%;
  height: 36px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1a73e8;
  cursor: pointer;
  font-size: 24px;
  border: solid 1px ${({theme}: StyledProps) => theme.content10};
  background-color: ${({theme}: StyledProps) => theme.content5};

  .can-hover &:hover {
    background-color: rgba(26, 115, 232, 0.05);
  }
`;


const UpdateableMarker = observer(({marker}: { marker: lpp_maps.IMarkerLayer }) => {
    const wave = useWave();
    const store = useContext(LppStoreContext);
    marker = store.markerWithUpdates(marker);
    let icon;
    if (marker.icon === lpp_maps.MarkerLayerIcon.div_icon && marker.divIcon) {
        icon = new DivIcon({
            html: marker.divIcon,
            className: 'div-icon',
            iconSize: [marker.iconSize ?? 30, marker.iconSize ?? 30],
        });
    } else {
        icon = getLeafletIcon(marker.icon, marker.iconSize);
    }

    return <Marker
        icon={icon}
        key={'marker-' + marker?.id}
        position={marker.location as LatLngExpression}
        zIndexOffset={-1000}
        pane={'shadowPane'}
    >
        <Popup>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: 'column'
            }}>
                <b>{marker.label}</b>
                {marker.description?.split('\n').map((e: string, i: number) => <span
                    key={'marker-' + marker?.id + i.toString()}
                    style={{textAlign: 'center'}}>{e}&nbsp;</span>)}
                <DirectionsButton
                    onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&destination=${marker.location?.lat},${marker.location?.lng}`, '_blank')?.focus()}
                    ref={wave}><MdDirections/></DirectionsButton>
            </div>
        </Popup>
    </Marker>;
});

function addLayer(layers: any[], wave: any, layer?: lpp_maps.ILayer) {
    const lines = layer?.lines;
    if (lines) {
        for (const line of lines) {
            layers.push(<Polyline interactive={false} pane={'shadowPane'}
                                  key={'line-' + line?.id}
                                  positions={line.points as LatLngExpression[]}
                                  pathOptions={{
                                      color: LppTypes.Color(line.color!),
                                      weight: line.weight ?? 4,
                                      dashArray: line.dashArray ?? undefined,
                                      lineCap: (line.cap ?? undefined) as (LineCapShape | undefined),
                                      lineJoin: (line.join ?? undefined) as (LineJoinShape | undefined),
                                  }}/>)
        }
    }

    const markers = layer?.markers;
    if (markers) {
        for (let marker of markers) {
            if (marker?.icon === lpp_maps.MarkerLayerIcon.radij_500m) {
                layers.unshift(
                    <Circle
                        key={marker.id + '500m'}
                        center={marker.location as LatLngExpression} radius={500} color={'#ED2790'}
                        fillColor={'rgba(248, 195, 218, 0.5)'} stroke={true} weight={1}
                    />
                );
            } else if (marker?.icon === lpp_maps.MarkerLayerIcon.radij_300m) {
                layers.push(
                    <Circle
                        key={marker.id + '300m'}
                        center={marker.location as LatLngExpression} radius={300} color={'#3f4096'}
                        fillColor={'rgba(0, 175, 218, 0.5)'} stroke={true} weight={1}
                    />
                );
            } else {
                layers.push(<UpdateableMarker key={layer?.id + '-' + marker?.id} marker={marker}/>)
            }
        }


    }

    const polygons = layer?.polygons;
    if (polygons) {
        for (const polygon of polygons) {
            layers.push(<Polygon
                key={'polygon-' + polygon?.id}
                positions={polygon.points as LatLngExpression[]}
                pathOptions={{
                    fillColor: LppTypes.Color(polygon.fillColor!),
                    fillOpacity: 1,
                    color: LppTypes.Color(polygon.borderColor!),
                    weight: polygon.borderWidth ?? 4,
                    dashArray: polygon.dashArray ?? undefined,
                    lineCap: (polygon.cap ?? undefined) as (LineCapShape | undefined),
                    lineJoin: (polygon.join ?? undefined) as (LineJoinShape | undefined),
                }}
            />);
        }
    }
}

export function getIconElement(icon?: lpp_maps.MarkerLayerIcon | null) {
    switch (icon) {
        case lpp_maps.MarkerLayerIcon.postajalisce:
            return <div style={{
                margin: '10px',
                backgroundColor: 'black',
                borderRadius: '100px',
                width: '10px',
                height: '10px'
            }}/>;
        case lpp_maps.MarkerLayerIcon.potniski_center_lpp:
            return <img src={IconProdajnoMesto} style={{width: '40px'}}/>;
        case lpp_maps.MarkerLayerIcon.urbanomat:
            return <img src={IconUrbanomat}/>;
        case lpp_maps.MarkerLayerIcon.klinko:
            return <img src={IconKlinko} style={{width: '20px'}}/>;
        case lpp_maps.MarkerLayerIcon.obmocja:
            return <img src={IconObmocja} style={{width: '20px'}}/>;
        case lpp_maps.MarkerLayerIcon.p_plus_r:
            return <img src={IconPPlusR} style={{width: '40px'}}/>;
        case lpp_maps.MarkerLayerIcon.pokopalisce:
            return <img src={IconPokopalisce} style={{width: '20px'}}/>;
        case lpp_maps.MarkerLayerIcon.posta:
            return <img src={IconPosta} style={{width: '20px'}}/>;
        case lpp_maps.MarkerLayerIcon.petrol:
            return <img src={IconPetrol} style={{width: '45px'}}/>;
        case lpp_maps.MarkerLayerIcon.radij_300m:
            return <img src={Icon300m} style={{width: '20px'}}/>;
        case lpp_maps.MarkerLayerIcon.radij_500m:
            return <img src={Icon500m} style={{width: '20px'}}/>;
        case lpp_maps.MarkerLayerIcon.bus:
            return <div style={{display: 'flex', width: '20px'}}><MdDirectionsBus color={'green'}/></div>;
        default:
            return <div style={{
                margin: '10px',
                backgroundColor: 'black',
                borderRadius: '100px',
                width: '10px',
                height: '10px'
            }}/>;
    }

}

export function getLeafletIcon(icon?: lpp_maps.MarkerLayerIcon | null, size?: number | null) {
    size = size === 0 ? undefined : size;
    const origSize = size;
    size = size ?? 35;
    switch (icon) {
        case lpp_maps.MarkerLayerIcon.postajalisce:
            return new DivIcon({
                html: `<div style="margin: 10px; background-color: black; border-radius: 100px; width: 10px; height: 10px;"/>`,
                className: '',
                iconSize: [size, size],
            });
        case lpp_maps.MarkerLayerIcon.potniski_center_lpp:
            return new DivIcon({
                html: `<div style="width: 100%; height: 100%; background-image: url('${IconProdajnoMesto}'); background-size: contain; background-repeat: no-repeat; background-position: center"/>`,
                className: '',
                iconSize: [size, size],
            });
        case lpp_maps.MarkerLayerIcon.urbanomat:
            return new DivIcon({
                html: `<div style="width: 100%; height: 100%; background-image: url('${IconUrbanomat}'); background-size: contain; background-repeat: no-repeat; background-position: center"/>`,
                className: '',
                iconSize: [size, size],
            });
        case lpp_maps.MarkerLayerIcon.klinko:
            return new DivIcon({
                html: `<div style="width: 100%; height: 100%; background-image: url('${IconKlinko}'); background-size: contain; background-repeat: no-repeat; background-position: center"/>`,
                className: '',
                iconSize: [size, size],
            });
        case lpp_maps.MarkerLayerIcon.obmocja:
            return new DivIcon({
                html: `<div style="width: 100%; height: 100%; background-image: url('${IconObmocja}'); background-size: contain; background-repeat: no-repeat; background-position: center"/>`,
                className: '',
                iconSize: [size, size],
            });
        case lpp_maps.MarkerLayerIcon.p_plus_r:
            return new DivIcon({
                html: `<div style="width: 100%; height: 100%; background-image: url('${IconPPlusR}'); background-size: contain; background-repeat: no-repeat; background-position: center"/>`,
                className: '',
                iconSize: [size, size],
            });
        case lpp_maps.MarkerLayerIcon.pokopalisce:
            return new DivIcon({
                html: `<div style="width: 100%; height: 100%; background-image: url('${IconPokopalisce}'); background-size: contain; background-repeat: no-repeat; background-position: center"/>`,
                className: '',
                iconSize: [origSize ?? 20, origSize ?? 20],
            });
        case lpp_maps.MarkerLayerIcon.posta:
            return new DivIcon({
                html: `<div style="width: 100%; height: 100%; background-image: url('${IconPosta}'); background-size: contain; background-repeat: no-repeat; background-position: center"/>`,
                className: '',
                iconSize: [origSize ?? 20, origSize ?? 20],
            });
        case lpp_maps.MarkerLayerIcon.petrol:
            return new DivIcon({
                html: `<div style="width: 100%; height: 100%; background-image: url('${IconPetrol}'); background-size: contain; background-repeat: no-repeat; background-position: center"/>`,
                className: '',
                iconSize: [origSize ?? 40, origSize ?? 40],
            });
        case lpp_maps.MarkerLayerIcon.radij_300m:
            return new DivIcon({
                html: `<div style="width: 100%; height: 100%; background-image: url('${Icon300m}'); background-size: contain; background-repeat: no-repeat; background-position: center"/>`,
                className: '',
                iconSize: [size, size],
            });
        case lpp_maps.MarkerLayerIcon.radij_500m:
            return new DivIcon({
                html: `<div style="width: 100%; height: 100%; background-image: url('${Icon500m}'); background-size: contain; background-repeat: no-repeat; background-position: center"/>`,
                className: '',
                iconSize: [size, size],
            });
        default:
            return new DivIcon({
                html: `<div style="margin: 10px; background-color: black; border-radius: 100px; width: 10px; height: 10px;"/>`,
                className: '',
                iconSize: [size, size],
            });
    }
}