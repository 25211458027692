import {lpp_maps, uploader, lpp_proge, sfiles, authentication, socket_api} from "./compiled";
import {SocketRxMessage, SocketRxMessageData, SocketTxMessage} from "proto_socket_typescript";

export namespace proto {

export class TxLogin extends SocketTxMessage<authentication.Login> {
    static type: string = 'login';
    proto: authentication.Login;
    protoClass = authentication.Login;
    

    constructor(proto: authentication.Login) {
        super(TxLogin.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.ILogin = {}) {
        return new TxLogin(authentication.Login.create(properties));
    }
}


export class RxLoginError extends SocketRxMessage<authentication.LoginError> {
    static type: string = 'login-error';
    proto = authentication.LoginError.create({});
    protoClass = authentication.LoginError;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginError.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginError(message);
    };
}


export class RxLoginToken extends SocketRxMessage<authentication.LoginToken> {
    static type: string = 'login-token';
    proto = authentication.LoginToken.create({});
    protoClass = authentication.LoginToken;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginToken.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginToken(message);
    };
}


export class RxTokenInvalid extends SocketRxMessage<authentication.TokenInvalid> {
    static type: string = 'token-invalid';
    proto = authentication.TokenInvalid.create({});
    protoClass = authentication.TokenInvalid;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxTokenInvalid.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxTokenInvalid(message);
    };
}


export class TxVerifyToken extends SocketTxMessage<authentication.VerifyToken> {
    static type: string = 'verify-token';
    proto: authentication.VerifyToken;
    protoClass = authentication.VerifyToken;
    

    constructor(proto: authentication.VerifyToken) {
        super(TxVerifyToken.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.IVerifyToken = {}) {
        return new TxVerifyToken(authentication.VerifyToken.create(properties));
    }
}


export class RxLayerData extends SocketRxMessage<lpp_maps.LayerData> {
    static type: string = 'layer';
    proto = lpp_maps.LayerData.create({});
    protoClass = lpp_maps.LayerData;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLayerData.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLayerData(message);
    };
}


export class RxLayers extends SocketRxMessage<lpp_maps.Layers> {
    static type: string = 'layers';
    proto = lpp_maps.Layers.create({});
    protoClass = lpp_maps.Layers;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLayers.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLayers(message);
    };
}


export class TxLoadLayer extends SocketTxMessage<lpp_maps.LoadLayer> {
    static type: string = 'load-layer';
    proto: lpp_maps.LoadLayer;
    protoClass = lpp_maps.LoadLayer;
    

    constructor(proto: lpp_maps.LoadLayer) {
        super(TxLoadLayer.type, true);
        this.proto = proto;
    }

    static create(properties: lpp_maps.ILoadLayer = {}) {
        return new TxLoadLayer(lpp_maps.LoadLayer.create(properties));
    }
}


export class TxLoadLayers extends SocketTxMessage<lpp_maps.LoadLayers> {
    static type: string = 'load-layers';
    proto: lpp_maps.LoadLayers;
    protoClass = lpp_maps.LoadLayers;
    

    constructor(proto: lpp_maps.LoadLayers) {
        super(TxLoadLayers.type, true);
        this.proto = proto;
    }

    static create(properties: lpp_maps.ILoadLayers = {}) {
        return new TxLoadLayers(lpp_maps.LoadLayers.create(properties));
    }
}


export class RxMarkerLayer extends SocketRxMessage<lpp_maps.MarkerLayer> {
    static type: string = 'marker-layer';
    proto = lpp_maps.MarkerLayer.create({});
    protoClass = lpp_maps.MarkerLayer;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxMarkerLayer.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxMarkerLayer(message);
    };
}


export class TxGetMagicLink extends SocketTxMessage<lpp_proge.GetMagicLink> {
    static type: string = 'get-magic-link';
    proto: lpp_proge.GetMagicLink;
    protoClass = lpp_proge.GetMagicLink;
    

    constructor(proto: lpp_proge.GetMagicLink) {
        super(TxGetMagicLink.type, true);
        this.proto = proto;
    }

    static create(properties: lpp_proge.IGetMagicLink = {}) {
        return new TxGetMagicLink(lpp_proge.GetMagicLink.create(properties));
    }
}


export class TxLoginMagicLink extends SocketTxMessage<lpp_proge.LoginMagicLink> {
    static type: string = 'login-magic-link';
    proto: lpp_proge.LoginMagicLink;
    protoClass = lpp_proge.LoginMagicLink;
    

    constructor(proto: lpp_proge.LoginMagicLink) {
        super(TxLoginMagicLink.type, true);
        this.proto = proto;
    }

    static create(properties: lpp_proge.ILoginMagicLink = {}) {
        return new TxLoginMagicLink(lpp_proge.LoginMagicLink.create(properties));
    }
}


export class RxLoginMagicLinkStatus extends SocketRxMessage<lpp_proge.LoginMagicLinkStatus> {
    static type: string = 'login-magic-link-status';
    proto = lpp_proge.LoginMagicLinkStatus.create({});
    protoClass = lpp_proge.LoginMagicLinkStatus;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginMagicLinkStatus.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginMagicLinkStatus(message);
    };
}


export class RxUserProfileData extends SocketRxMessage<lpp_proge.UserProfileData> {
    static type: string = 'user-profile-data';
    proto = lpp_proge.UserProfileData.create({});
    protoClass = lpp_proge.UserProfileData;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUserProfileData.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUserProfileData(message);
    };
}


export class TxDeleteFile extends SocketTxMessage<sfiles.DeleteFile> {
    static type: string = 'delete-file';
    proto: sfiles.DeleteFile;
    protoClass = sfiles.DeleteFile;
    

    constructor(proto: sfiles.DeleteFile) {
        super(TxDeleteFile.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IDeleteFile = {}) {
        return new TxDeleteFile(sfiles.DeleteFile.create(properties));
    }
}


export class RxUploadDone extends SocketRxMessage<sfiles.UploadDone> {
    static type: string = 'upload-done';
    proto = sfiles.UploadDone.create({});
    protoClass = sfiles.UploadDone;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadDone.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadDone(message);
    };
}


export class TxUploadEnd extends SocketTxMessage<sfiles.UploadEnd> {
    static type: string = 'upload-end';
    proto: sfiles.UploadEnd;
    protoClass = sfiles.UploadEnd;
    

    constructor(proto: sfiles.UploadEnd) {
        super(TxUploadEnd.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IUploadEnd = {}) {
        return new TxUploadEnd(sfiles.UploadEnd.create(properties));
    }
}


export class RxUploadProgress extends SocketRxMessage<sfiles.UploadProgress> {
    static type: string = 'upload-progress';
    proto = sfiles.UploadProgress.create({});
    protoClass = sfiles.UploadProgress;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadProgress.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadProgress(message);
    };
}


export class TxUploadStart extends SocketTxMessage<sfiles.UploadStart> {
    static type: string = 'upload-start';
    proto: sfiles.UploadStart;
    protoClass = sfiles.UploadStart;
    

    constructor(proto: sfiles.UploadStart) {
        super(TxUploadStart.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IUploadStart = {}) {
        return new TxUploadStart(sfiles.UploadStart.create(properties));
    }
}


export class RxUploadStartSlot extends SocketRxMessage<sfiles.UploadStartSlot> {
    static type: string = 'upload-start';
    proto = sfiles.UploadStartSlot.create({});
    protoClass = sfiles.UploadStartSlot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadStartSlot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadStartSlot(message);
    };
}


export class RxAck extends SocketRxMessage<socket_api.Ack> {
    static type: string = 'ack';
    proto = socket_api.Ack.create({});
    protoClass = socket_api.Ack;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAck.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAck(message);
    };
}


export class RxAsyncProgress extends SocketRxMessage<socket_api.AsyncProgress> {
    static type: string = 'async-progress';
    proto = socket_api.AsyncProgress.create({});
    protoClass = socket_api.AsyncProgress;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsyncProgress.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsyncProgress(message);
    };
}


export class RxUpgradeApiVersion extends SocketRxMessage<socket_api.UpgradeApiVersion> {
    static type: string = 'upgrade-api-version';
    proto = socket_api.UpgradeApiVersion.create({});
    protoClass = socket_api.UpgradeApiVersion;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUpgradeApiVersion.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUpgradeApiVersion(message);
    };
}


export class TxDeleteUFile extends SocketTxMessage<uploader.DeleteUFile> {
    static type: string = 'delete-ufile';
    proto: uploader.DeleteUFile;
    protoClass = uploader.DeleteUFile;
    

    constructor(proto: uploader.DeleteUFile) {
        super(TxDeleteUFile.type, true);
        this.proto = proto;
    }

    static create(properties: uploader.IDeleteUFile = {}) {
        return new TxDeleteUFile(uploader.DeleteUFile.create(properties));
    }
}


export class RxUploadSlot extends SocketRxMessage<uploader.UploadSlot> {
    static type: string = 'upload-slot';
    proto = uploader.UploadSlot.create({});
    protoClass = uploader.UploadSlot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadSlot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadSlot(message);
    };
}


export class RxUploadTask extends SocketRxMessage<uploader.UploadTask> {
    static type: string = 'upload-task';
    proto = uploader.UploadTask.create({});
    protoClass = uploader.UploadTask;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadTask.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadTask(message);
    };
}


export class TxUploadUFile extends SocketTxMessage<uploader.UploadUFile> {
    static type: string = 'upload-ufile';
    proto: uploader.UploadUFile;
    protoClass = uploader.UploadUFile;
    

    constructor(proto: uploader.UploadUFile) {
        super(TxUploadUFile.type, true);
        this.proto = proto;
    }

    static create(properties: uploader.IUploadUFile = {}) {
        return new TxUploadUFile(uploader.UploadUFile.create(properties));
    }
}


        export const rxMessages: SocketRxMessage<any>[] = [
            new RxLoginError(),
    new RxLoginToken(),
    new RxTokenInvalid(),
    new RxLayerData(),
    new RxLayers(),
    new RxMarkerLayer(),
    new RxLoginMagicLinkStatus(),
    new RxUserProfileData(),
    new RxUploadDone(),
    new RxUploadProgress(),
    new RxUploadStartSlot(),
    new RxAck(),
    new RxAsyncProgress(),
    new RxUpgradeApiVersion(),
    new RxUploadSlot(),
    new RxUploadTask()
        ];
}