import styled from "styled-components";
import {StyledProps} from "../theme";

export const LppButton = styled.div<{ width?: string, height?: string, disabled?: boolean }>`
  background-color: ${({theme}: StyledProps) => theme.primary};
  padding: 0 30px 0 30px;
  border-radius: 5px;
  height: ${({height}) => height || '62px'};
  width: ${({width}) => width || '100%'};
  display: flex;
  align-items: center;
  ${({theme}: StyledProps) => theme.textTheme.button.css};
  color: white;
  box-sizing: border-box;
  cursor: ${({disabled}) => disabled ? 'auto' : 'pointer'};
  opacity: ${({disabled}) => disabled ? 0.5 : 1};
  pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
  user-select: none;
`;

export const LppFab = styled.div`
  position: fixed;
  cursor: pointer;
  background-color: ${({theme}: StyledProps) => theme.primary};
  color: ${({theme}: StyledProps) => theme.neutral};
  font-size: 20px;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconButton = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 8px;
  border-radius: 100px;
  border: solid 1px ${({theme}: StyledProps) => theme.primary10};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  color: ${({theme}: StyledProps) => theme.primary};
`;