import React, {createContext, useEffect} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {LppScaffold} from "./components/lpp_scaffold";
import MultiProvider from "./utils";
import {ThemeProvider, useTheme} from "styled-components";
import {Theme, LppTheme} from "./theme";
import {SocketApi} from "proto_socket_typescript";
import {LppStore} from "./stores/lpp_store";
import {observer} from "mobx-react-lite";
import {LppMap} from "./maps/lpp_map";
import {ProgeOverlay} from "./overlays/proge_overlay";
import {LocationsOverlay} from "./overlays/locations_overlay";
import {MobileOverlay} from "./overlays/mobile_overlay";
import {ProgeToolbarOverlay} from "./overlays/proge_toolbar_overlay";

const port = window.location.port === '3000' ? '8000' : window.location.port;
export const api = new SocketApi(`${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.hostname}:${port}/ws/`);
const lppStore = new LppStore(api);
export const LppStoreContext = createContext<LppStore>(lppStore);
export const ApiContext = createContext<SocketApi>(api);

function App() {
    useEffect(() => {
        const resize = () => {
            lppStore.isMobile = window.innerWidth < 1000;
        };
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        };
    });

    return (
        <MultiProvider providers={[
            <ThemeProvider theme={LppTheme}/>,
            <ApiContext.Provider value={api}/>,
            <LppStoreContext.Provider value={lppStore}/>,
        ]}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path={'/'}
                        element={<LppScaffold>
                            <LppMap/>
                            {!lppStore.isMobile && <ProgeOverlay/>}
                            {!lppStore.isMobile && <LocationsOverlay/>}
                            {lppStore.isMobile && <MobileOverlay/>}
                            {lppStore.isMobile && <ProgeToolbarOverlay/>}
                        </LppScaffold>}
                    />
                </Routes>
            </BrowserRouter>
        </MultiProvider>
    );
}

export default observer(App);
