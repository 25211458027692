import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "../theme";
import React, {useContext} from "react";
import {MdCheck, MdClear, MdDoneAll} from 'react-icons/md'
import {LppStoreContext} from "../App";
import {lpp_maps} from "../proto/compiled";
import {getLeafletIcon, getIconElement} from "../maps/lpp_map";
import useWave from "use-wave";
import {IconButton} from "../components/buttons";
import {proto} from "../proto/messages";
import {LoadingOverlay} from "../components/overlays";

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 16px;
  top: 16px;
  z-index: 999;
  width: 300px;
  background-color: white;
  border-radius: 12px;
  padding: 16px;
  overflow: hidden;
`;


const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding-top: 8px;
  padding-bottom: 16px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const LocationsOverlay = observer(() => {
    const store = useContext(LppStoreContext);
    const wave = useWave();
    const provider = useContext(LppStoreContext);

    return <Container>
        <LoadingOverlay overlayStyle={{
            margin: '-16px',
        }} builder={(action) => <ContentContainer>
            <div style={{
                display: 'flex',
                position: 'absolute',
                right: '4px',
                bottom: '-8px',
                zIndex: '700'
            }}>
                <IconButton onClick={() => {
                    const load: string[] = [];
                    provider.layers?.layers?.filter((l: any) => l.type === lpp_maps.LayerType.other).forEach((l) => {
                        if (!provider.layerData.has(l.id!)) {
                            load.push(l.id!);
                        }
                        provider.visibleLayers.add(l.id!);
                    });
                    if (load.length) {
                        action(provider.api.sendMessage(proto.TxLoadLayer.create({
                            ids: load
                        }), {ack: true}));
                    }
                }} ref={wave}><MdDoneAll/></IconButton>
                <IconButton onClick={() => {
                    provider.layers?.layers?.filter((l: any) => l.type === lpp_maps.LayerType.other).forEach((l) => provider.visibleLayers.delete(l.id));
                }} ref={wave}><MdClear/></IconButton>
            </div>
            {store.layers && store.layers.layers?.filter((l: lpp_maps.ILayer) => l.type === lpp_maps.LayerType.other)?.map((l: lpp_maps.ILayer) =>
                <LocationsEntry
                    onClick={() => store.toggleLayer(l)}
                    icon={l.legendIcon}
                    label={l.label}
                    key={l.id}
                    selected={store.visibleLayers.has(l.id)}/>)}
            {store.layers && <LocationsEntry
                onClick={() => {
                    store.showBusses = !store.showBusses;
                    store.updateBusses();
                }}
                icon={lpp_maps.MarkerLayerIcon.bus}
                label={'lokacije avtobusov v realnem času'}
                selected={store.showBusses}/>}
        </ContentContainer>
        }/>
    </Container>;
});

const LocationsEntrySelected = styled.div<{ selected: boolean }>`
  position: absolute;
  display: flex;
  opacity: ${({selected}) => selected ? '1' : '0'};
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
  bottom: 0;
  aspect-ratio: 1;
  transition: all 300ms;
`;

const LocationsEntryContainer = styled.div.attrs({tabIndex: -1})<{ selected: boolean, start?: boolean }>`
  transition: all 300ms;
  display: flex;
  position: relative;
  align-items: center;
  height: 24px;
  width: 100%;
  border-radius: 12px;
  justify-content: ${({theme, start}: StyledProps) => start ? 'start' : 'center'};
  border: solid 1px ${({theme, selected}: StyledProps) => selected ? theme.content10 : 'transparent'};
  background-color: ${({theme, selected}: StyledProps) => selected ? theme.content5 : 'transparent'};
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 8px;

  .can-hover &:hover {
    background-color: ${({theme}: StyledProps) => theme.content10};
  }
`;

export const LocationsEntry = observer(({
                                            icon,
                                            onClick,
                                            label,
                                            selected,
                                            start,
                                        }: { onClick: () => any, icon?: lpp_maps.MarkerLayerIcon | null, label?: string | null, selected: boolean, start?: boolean }) => {
    const theme = useTheme() as Theme;
    const wave = useWave();
    return <LocationsEntryContainer ref={wave} selected={selected} onClick={() => onClick()} start={start}>
        <LocationsEntrySelected selected={selected}>
            <MdCheck style={{marginRight: 'auto'}} color={theme.primary}/>
        </LocationsEntrySelected>
        <div style={start ? {width: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center'} : {}}>
            {getIconElement(icon)}
        </div>
        <theme.textTheme.headline6.span style={{marginLeft: '8px', marginRight: '16px'}}>
            {label}
        </theme.textTheme.headline6.span>
    </LocationsEntryContainer>;
})
