import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "../theme";
import {OverlayContainer} from "./overlays";
import {IconButton, LppButton} from "./buttons";
import React, {CSSProperties, useContext, useEffect, useState} from "react";
import useWave from "use-wave";
import {observer} from "mobx-react-lite";
import {lpp_maps} from "../proto/compiled";
import {proto} from "../proto/messages";
import {MdClear, MdDoneAll} from "react-icons/md";
import {LppStoreContext} from "../App";

export const DialogFooter = styled.div`
  position: relative;
  width: 100%;
  border-top: solid 1px #CCCCCC;
  background-color: ${({theme}: StyledProps) => theme.content10};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DialogContainer = styled.div<{ width?: string, height?: string }>`
  position: relative;
  background-color: ${({theme}: StyledProps) => theme.neutral};
  margin: 16px;
  border-radius: 5px;
  flex-grow: 1;
  overflow: hidden;
  box-shadow: 0 0.25em 0.5em rgba(0, 45, 98, .3);
  max-width: ${({width}) => width};
  max-height: ${({height}) => height};
  width: ${({width}) => width ? 'calc(100% - 32px)' : ''};
  height: ${({width}) => width ? 'calc(100% - 32px)' : ''};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContentUnitFrame = styled.div<{ padding?: string }>`
  position: relative;
  padding: ${({padding}) => padding};
  border: solid 1px ${({theme}: StyledProps) => theme.content};
`;

export const YesNoDialog = ({children, result}: { children?: any, result: (result: boolean) => any }) => {
    const [first, setFirst] = useState(true);
    useEffect(() => setFirst(false), []);
    const wave = useWave();

    return <OverlayContainer hidden={first}>
        <DialogContainer style={{padding: '0', width: '500px'}}>
            <div style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                minHeight: '100px',
                padding: '16px 50px 16px 50px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                {children}
            </div>
            <DialogFooter style={{height: '50px'}}>
                <LppButton
                    ref={wave}
                    onClick={() => {
                        setFirst(true);
                        setTimeout(() => result(false), 300);
                    }}
                    height={'30px'}
                    width={'110px'}
                    style={{padding: 0, justifyContent: 'center'}}
                >NE</LppButton>
                <LppButton
                    ref={wave}
                    onClick={() => {
                        setFirst(true);
                        setTimeout(() => result(true), 300);
                    }}
                    height={'30px'}
                    width={'110px'}
                    style={{padding: 0, justifyContent: 'center', marginLeft: '16px'}}
                >DA</LppButton>
            </DialogFooter>
        </DialogContainer>
    </OverlayContainer>;
};

export const LppDialog = ({
                              children,
                              footer,
                              onClose,
                              style
                          }: { children?: any, footer?: any, onClose: () => any, style?: CSSProperties }) => {
    const [first, setFirst] = useState(true);
    useEffect(() => setFirst(false), []);
    const wave = useWave();
    const provider = useContext(LppStoreContext);
    const theme = useTheme() as Theme;

    return <OverlayContainer hidden={first}>
        <DialogContainer style={{alignItems: 'stretch'}}>
            <div style={{
                flexGrow: '1',
                flexDirection: 'column',
                display: "flex",
                alignItems: "stretch",
                height: '0',
                ...(style ?? {})
            }}>
                {children}
            </div>
            <DialogFooter style={{height: '50px'}}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    right: '32px',
                    bottom: '0',
                    top: '0',
                    zIndex: '700',
                    transition: 'all 300ms',
                    opacity: provider.visibleLayers.size > 0 ? '1' : '0',
                    pointerEvents: provider.visibleLayers.size > 0 ? 'auto' : 'none',
                }}>
                    <IconButton onClick={() => {
                        provider.layers?.layers?.forEach((l) => provider.visibleLayers.delete(l.id));
                    }} ref={wave} style={{backgroundColor: theme.primary, color: 'white'}}><MdClear/></IconButton>
                </div>
                <LppButton
                    ref={wave}
                    onClick={() => {
                        setFirst(true);
                        setTimeout(() => onClose(), 300);
                    }}
                    height={'30px'}
                    width={'200px'}
                    style={{padding: 0, justifyContent: 'center'}}
                >ZAPRI</LppButton>
            </DialogFooter>
        </DialogContainer>
    </OverlayContainer>;
};