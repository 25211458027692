/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const lpp_types = $root.lpp_types = (() => {

    /**
     * Namespace lpp_types.
     * @exports lpp_types
     * @namespace
     */
    const lpp_types = {};

    lpp_types.Color = (function() {

        /**
         * Properties of a Color.
         * @memberof lpp_types
         * @interface IColor
         * @property {number|null} [r] Color r
         * @property {number|null} [g] Color g
         * @property {number|null} [b] Color b
         * @property {number|null} [a] Color a
         */

        /**
         * Constructs a new Color.
         * @memberof lpp_types
         * @classdesc Represents a Color.
         * @implements IColor
         * @constructor
         * @param {lpp_types.IColor=} [properties] Properties to set
         */
        function Color(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Color r.
         * @member {number} r
         * @memberof lpp_types.Color
         * @instance
         */
        Color.prototype.r = 0;

        /**
         * Color g.
         * @member {number} g
         * @memberof lpp_types.Color
         * @instance
         */
        Color.prototype.g = 0;

        /**
         * Color b.
         * @member {number} b
         * @memberof lpp_types.Color
         * @instance
         */
        Color.prototype.b = 0;

        /**
         * Color a.
         * @member {number} a
         * @memberof lpp_types.Color
         * @instance
         */
        Color.prototype.a = 0;

        /**
         * Creates a new Color instance using the specified properties.
         * @function create
         * @memberof lpp_types.Color
         * @static
         * @param {lpp_types.IColor=} [properties] Properties to set
         * @returns {lpp_types.Color} Color instance
         */
        Color.create = function create(properties) {
            return new Color(properties);
        };

        /**
         * Encodes the specified Color message. Does not implicitly {@link lpp_types.Color.verify|verify} messages.
         * @function encode
         * @memberof lpp_types.Color
         * @static
         * @param {lpp_types.IColor} message Color message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Color.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.r != null && Object.hasOwnProperty.call(message, "r"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.r);
            if (message.g != null && Object.hasOwnProperty.call(message, "g"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.g);
            if (message.b != null && Object.hasOwnProperty.call(message, "b"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.b);
            if (message.a != null && Object.hasOwnProperty.call(message, "a"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.a);
            return writer;
        };

        /**
         * Encodes the specified Color message, length delimited. Does not implicitly {@link lpp_types.Color.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lpp_types.Color
         * @static
         * @param {lpp_types.IColor} message Color message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Color.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Color message from the specified reader or buffer.
         * @function decode
         * @memberof lpp_types.Color
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lpp_types.Color} Color
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Color.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lpp_types.Color();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.r = reader.int32();
                        break;
                    }
                case 2: {
                        message.g = reader.int32();
                        break;
                    }
                case 3: {
                        message.b = reader.int32();
                        break;
                    }
                case 4: {
                        message.a = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Color message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lpp_types.Color
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lpp_types.Color} Color
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Color.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Color message.
         * @function verify
         * @memberof lpp_types.Color
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Color.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.r != null && message.hasOwnProperty("r"))
                if (!$util.isInteger(message.r))
                    return "r: integer expected";
            if (message.g != null && message.hasOwnProperty("g"))
                if (!$util.isInteger(message.g))
                    return "g: integer expected";
            if (message.b != null && message.hasOwnProperty("b"))
                if (!$util.isInteger(message.b))
                    return "b: integer expected";
            if (message.a != null && message.hasOwnProperty("a"))
                if (!$util.isInteger(message.a))
                    return "a: integer expected";
            return null;
        };

        /**
         * Creates a Color message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lpp_types.Color
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lpp_types.Color} Color
         */
        Color.fromObject = function fromObject(object) {
            if (object instanceof $root.lpp_types.Color)
                return object;
            let message = new $root.lpp_types.Color();
            if (object.r != null)
                message.r = object.r | 0;
            if (object.g != null)
                message.g = object.g | 0;
            if (object.b != null)
                message.b = object.b | 0;
            if (object.a != null)
                message.a = object.a | 0;
            return message;
        };

        /**
         * Creates a plain object from a Color message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lpp_types.Color
         * @static
         * @param {lpp_types.Color} message Color
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Color.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.r = 0;
                object.g = 0;
                object.b = 0;
                object.a = 0;
            }
            if (message.r != null && message.hasOwnProperty("r"))
                object.r = message.r;
            if (message.g != null && message.hasOwnProperty("g"))
                object.g = message.g;
            if (message.b != null && message.hasOwnProperty("b"))
                object.b = message.b;
            if (message.a != null && message.hasOwnProperty("a"))
                object.a = message.a;
            return object;
        };

        /**
         * Converts this Color to JSON.
         * @function toJSON
         * @memberof lpp_types.Color
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Color.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Color
         * @function getTypeUrl
         * @memberof lpp_types.Color
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Color.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/lpp_types.Color";
        };

        return Color;
    })();

    lpp_types.LatLng = (function() {

        /**
         * Properties of a LatLng.
         * @memberof lpp_types
         * @interface ILatLng
         * @property {number|null} [lat] LatLng lat
         * @property {number|null} [lng] LatLng lng
         */

        /**
         * Constructs a new LatLng.
         * @memberof lpp_types
         * @classdesc Represents a LatLng.
         * @implements ILatLng
         * @constructor
         * @param {lpp_types.ILatLng=} [properties] Properties to set
         */
        function LatLng(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LatLng lat.
         * @member {number} lat
         * @memberof lpp_types.LatLng
         * @instance
         */
        LatLng.prototype.lat = 0;

        /**
         * LatLng lng.
         * @member {number} lng
         * @memberof lpp_types.LatLng
         * @instance
         */
        LatLng.prototype.lng = 0;

        /**
         * Creates a new LatLng instance using the specified properties.
         * @function create
         * @memberof lpp_types.LatLng
         * @static
         * @param {lpp_types.ILatLng=} [properties] Properties to set
         * @returns {lpp_types.LatLng} LatLng instance
         */
        LatLng.create = function create(properties) {
            return new LatLng(properties);
        };

        /**
         * Encodes the specified LatLng message. Does not implicitly {@link lpp_types.LatLng.verify|verify} messages.
         * @function encode
         * @memberof lpp_types.LatLng
         * @static
         * @param {lpp_types.ILatLng} message LatLng message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LatLng.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.lat);
            if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.lng);
            return writer;
        };

        /**
         * Encodes the specified LatLng message, length delimited. Does not implicitly {@link lpp_types.LatLng.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lpp_types.LatLng
         * @static
         * @param {lpp_types.ILatLng} message LatLng message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LatLng.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LatLng message from the specified reader or buffer.
         * @function decode
         * @memberof lpp_types.LatLng
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lpp_types.LatLng} LatLng
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LatLng.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lpp_types.LatLng();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.lat = reader.double();
                        break;
                    }
                case 2: {
                        message.lng = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LatLng message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lpp_types.LatLng
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lpp_types.LatLng} LatLng
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LatLng.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LatLng message.
         * @function verify
         * @memberof lpp_types.LatLng
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LatLng.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.lat != null && message.hasOwnProperty("lat"))
                if (typeof message.lat !== "number")
                    return "lat: number expected";
            if (message.lng != null && message.hasOwnProperty("lng"))
                if (typeof message.lng !== "number")
                    return "lng: number expected";
            return null;
        };

        /**
         * Creates a LatLng message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lpp_types.LatLng
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lpp_types.LatLng} LatLng
         */
        LatLng.fromObject = function fromObject(object) {
            if (object instanceof $root.lpp_types.LatLng)
                return object;
            let message = new $root.lpp_types.LatLng();
            if (object.lat != null)
                message.lat = Number(object.lat);
            if (object.lng != null)
                message.lng = Number(object.lng);
            return message;
        };

        /**
         * Creates a plain object from a LatLng message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lpp_types.LatLng
         * @static
         * @param {lpp_types.LatLng} message LatLng
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LatLng.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.lat = 0;
                object.lng = 0;
            }
            if (message.lat != null && message.hasOwnProperty("lat"))
                object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
            if (message.lng != null && message.hasOwnProperty("lng"))
                object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
            return object;
        };

        /**
         * Converts this LatLng to JSON.
         * @function toJSON
         * @memberof lpp_types.LatLng
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LatLng.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LatLng
         * @function getTypeUrl
         * @memberof lpp_types.LatLng
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LatLng.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/lpp_types.LatLng";
        };

        return LatLng;
    })();

    return lpp_types;
})();

export const lpp_maps = $root.lpp_maps = (() => {

    /**
     * Namespace lpp_maps.
     * @exports lpp_maps
     * @namespace
     */
    const lpp_maps = {};

    lpp_maps.LoadLayers = (function() {

        /**
         * Properties of a LoadLayers.
         * @memberof lpp_maps
         * @interface ILoadLayers
         */

        /**
         * Constructs a new LoadLayers.
         * @memberof lpp_maps
         * @classdesc Represents a LoadLayers.
         * @implements ILoadLayers
         * @constructor
         * @param {lpp_maps.ILoadLayers=} [properties] Properties to set
         */
        function LoadLayers(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new LoadLayers instance using the specified properties.
         * @function create
         * @memberof lpp_maps.LoadLayers
         * @static
         * @param {lpp_maps.ILoadLayers=} [properties] Properties to set
         * @returns {lpp_maps.LoadLayers} LoadLayers instance
         */
        LoadLayers.create = function create(properties) {
            return new LoadLayers(properties);
        };

        /**
         * Encodes the specified LoadLayers message. Does not implicitly {@link lpp_maps.LoadLayers.verify|verify} messages.
         * @function encode
         * @memberof lpp_maps.LoadLayers
         * @static
         * @param {lpp_maps.ILoadLayers} message LoadLayers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadLayers.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified LoadLayers message, length delimited. Does not implicitly {@link lpp_maps.LoadLayers.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lpp_maps.LoadLayers
         * @static
         * @param {lpp_maps.ILoadLayers} message LoadLayers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadLayers.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadLayers message from the specified reader or buffer.
         * @function decode
         * @memberof lpp_maps.LoadLayers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lpp_maps.LoadLayers} LoadLayers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadLayers.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lpp_maps.LoadLayers();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadLayers message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lpp_maps.LoadLayers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lpp_maps.LoadLayers} LoadLayers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadLayers.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadLayers message.
         * @function verify
         * @memberof lpp_maps.LoadLayers
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadLayers.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a LoadLayers message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lpp_maps.LoadLayers
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lpp_maps.LoadLayers} LoadLayers
         */
        LoadLayers.fromObject = function fromObject(object) {
            if (object instanceof $root.lpp_maps.LoadLayers)
                return object;
            return new $root.lpp_maps.LoadLayers();
        };

        /**
         * Creates a plain object from a LoadLayers message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lpp_maps.LoadLayers
         * @static
         * @param {lpp_maps.LoadLayers} message LoadLayers
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadLayers.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this LoadLayers to JSON.
         * @function toJSON
         * @memberof lpp_maps.LoadLayers
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadLayers.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoadLayers
         * @function getTypeUrl
         * @memberof lpp_maps.LoadLayers
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoadLayers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/lpp_maps.LoadLayers";
        };

        return LoadLayers;
    })();

    lpp_maps.LoadLayer = (function() {

        /**
         * Properties of a LoadLayer.
         * @memberof lpp_maps
         * @interface ILoadLayer
         * @property {Array.<string>|null} [ids] LoadLayer ids
         */

        /**
         * Constructs a new LoadLayer.
         * @memberof lpp_maps
         * @classdesc Represents a LoadLayer.
         * @implements ILoadLayer
         * @constructor
         * @param {lpp_maps.ILoadLayer=} [properties] Properties to set
         */
        function LoadLayer(properties) {
            this.ids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadLayer ids.
         * @member {Array.<string>} ids
         * @memberof lpp_maps.LoadLayer
         * @instance
         */
        LoadLayer.prototype.ids = $util.emptyArray;

        /**
         * Creates a new LoadLayer instance using the specified properties.
         * @function create
         * @memberof lpp_maps.LoadLayer
         * @static
         * @param {lpp_maps.ILoadLayer=} [properties] Properties to set
         * @returns {lpp_maps.LoadLayer} LoadLayer instance
         */
        LoadLayer.create = function create(properties) {
            return new LoadLayer(properties);
        };

        /**
         * Encodes the specified LoadLayer message. Does not implicitly {@link lpp_maps.LoadLayer.verify|verify} messages.
         * @function encode
         * @memberof lpp_maps.LoadLayer
         * @static
         * @param {lpp_maps.ILoadLayer} message LoadLayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadLayer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ids != null && message.ids.length)
                for (let i = 0; i < message.ids.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.ids[i]);
            return writer;
        };

        /**
         * Encodes the specified LoadLayer message, length delimited. Does not implicitly {@link lpp_maps.LoadLayer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lpp_maps.LoadLayer
         * @static
         * @param {lpp_maps.ILoadLayer} message LoadLayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadLayer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadLayer message from the specified reader or buffer.
         * @function decode
         * @memberof lpp_maps.LoadLayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lpp_maps.LoadLayer} LoadLayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadLayer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lpp_maps.LoadLayer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.ids && message.ids.length))
                            message.ids = [];
                        message.ids.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadLayer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lpp_maps.LoadLayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lpp_maps.LoadLayer} LoadLayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadLayer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadLayer message.
         * @function verify
         * @memberof lpp_maps.LoadLayer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadLayer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ids != null && message.hasOwnProperty("ids")) {
                if (!Array.isArray(message.ids))
                    return "ids: array expected";
                for (let i = 0; i < message.ids.length; ++i)
                    if (!$util.isString(message.ids[i]))
                        return "ids: string[] expected";
            }
            return null;
        };

        /**
         * Creates a LoadLayer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lpp_maps.LoadLayer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lpp_maps.LoadLayer} LoadLayer
         */
        LoadLayer.fromObject = function fromObject(object) {
            if (object instanceof $root.lpp_maps.LoadLayer)
                return object;
            let message = new $root.lpp_maps.LoadLayer();
            if (object.ids) {
                if (!Array.isArray(object.ids))
                    throw TypeError(".lpp_maps.LoadLayer.ids: array expected");
                message.ids = [];
                for (let i = 0; i < object.ids.length; ++i)
                    message.ids[i] = String(object.ids[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a LoadLayer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lpp_maps.LoadLayer
         * @static
         * @param {lpp_maps.LoadLayer} message LoadLayer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadLayer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.ids = [];
            if (message.ids && message.ids.length) {
                object.ids = [];
                for (let j = 0; j < message.ids.length; ++j)
                    object.ids[j] = message.ids[j];
            }
            return object;
        };

        /**
         * Converts this LoadLayer to JSON.
         * @function toJSON
         * @memberof lpp_maps.LoadLayer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadLayer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoadLayer
         * @function getTypeUrl
         * @memberof lpp_maps.LoadLayer
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoadLayer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/lpp_maps.LoadLayer";
        };

        return LoadLayer;
    })();

    lpp_maps.Layers = (function() {

        /**
         * Properties of a Layers.
         * @memberof lpp_maps
         * @interface ILayers
         * @property {Array.<lpp_maps.ILayer>|null} [layers] Layers layers
         */

        /**
         * Constructs a new Layers.
         * @memberof lpp_maps
         * @classdesc Represents a Layers.
         * @implements ILayers
         * @constructor
         * @param {lpp_maps.ILayers=} [properties] Properties to set
         */
        function Layers(properties) {
            this.layers = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Layers layers.
         * @member {Array.<lpp_maps.ILayer>} layers
         * @memberof lpp_maps.Layers
         * @instance
         */
        Layers.prototype.layers = $util.emptyArray;

        /**
         * Creates a new Layers instance using the specified properties.
         * @function create
         * @memberof lpp_maps.Layers
         * @static
         * @param {lpp_maps.ILayers=} [properties] Properties to set
         * @returns {lpp_maps.Layers} Layers instance
         */
        Layers.create = function create(properties) {
            return new Layers(properties);
        };

        /**
         * Encodes the specified Layers message. Does not implicitly {@link lpp_maps.Layers.verify|verify} messages.
         * @function encode
         * @memberof lpp_maps.Layers
         * @static
         * @param {lpp_maps.ILayers} message Layers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Layers.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.layers != null && message.layers.length)
                for (let i = 0; i < message.layers.length; ++i)
                    $root.lpp_maps.Layer.encode(message.layers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Layers message, length delimited. Does not implicitly {@link lpp_maps.Layers.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lpp_maps.Layers
         * @static
         * @param {lpp_maps.ILayers} message Layers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Layers.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Layers message from the specified reader or buffer.
         * @function decode
         * @memberof lpp_maps.Layers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lpp_maps.Layers} Layers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Layers.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lpp_maps.Layers();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.layers && message.layers.length))
                            message.layers = [];
                        message.layers.push($root.lpp_maps.Layer.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Layers message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lpp_maps.Layers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lpp_maps.Layers} Layers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Layers.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Layers message.
         * @function verify
         * @memberof lpp_maps.Layers
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Layers.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.layers != null && message.hasOwnProperty("layers")) {
                if (!Array.isArray(message.layers))
                    return "layers: array expected";
                for (let i = 0; i < message.layers.length; ++i) {
                    let error = $root.lpp_maps.Layer.verify(message.layers[i]);
                    if (error)
                        return "layers." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Layers message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lpp_maps.Layers
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lpp_maps.Layers} Layers
         */
        Layers.fromObject = function fromObject(object) {
            if (object instanceof $root.lpp_maps.Layers)
                return object;
            let message = new $root.lpp_maps.Layers();
            if (object.layers) {
                if (!Array.isArray(object.layers))
                    throw TypeError(".lpp_maps.Layers.layers: array expected");
                message.layers = [];
                for (let i = 0; i < object.layers.length; ++i) {
                    if (typeof object.layers[i] !== "object")
                        throw TypeError(".lpp_maps.Layers.layers: object expected");
                    message.layers[i] = $root.lpp_maps.Layer.fromObject(object.layers[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Layers message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lpp_maps.Layers
         * @static
         * @param {lpp_maps.Layers} message Layers
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Layers.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.layers = [];
            if (message.layers && message.layers.length) {
                object.layers = [];
                for (let j = 0; j < message.layers.length; ++j)
                    object.layers[j] = $root.lpp_maps.Layer.toObject(message.layers[j], options);
            }
            return object;
        };

        /**
         * Converts this Layers to JSON.
         * @function toJSON
         * @memberof lpp_maps.Layers
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Layers.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Layers
         * @function getTypeUrl
         * @memberof lpp_maps.Layers
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Layers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/lpp_maps.Layers";
        };

        return Layers;
    })();

    lpp_maps.LayerData = (function() {

        /**
         * Properties of a LayerData.
         * @memberof lpp_maps
         * @interface ILayerData
         * @property {Array.<lpp_maps.ILayer>|null} [layers] LayerData layers
         */

        /**
         * Constructs a new LayerData.
         * @memberof lpp_maps
         * @classdesc Represents a LayerData.
         * @implements ILayerData
         * @constructor
         * @param {lpp_maps.ILayerData=} [properties] Properties to set
         */
        function LayerData(properties) {
            this.layers = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LayerData layers.
         * @member {Array.<lpp_maps.ILayer>} layers
         * @memberof lpp_maps.LayerData
         * @instance
         */
        LayerData.prototype.layers = $util.emptyArray;

        /**
         * Creates a new LayerData instance using the specified properties.
         * @function create
         * @memberof lpp_maps.LayerData
         * @static
         * @param {lpp_maps.ILayerData=} [properties] Properties to set
         * @returns {lpp_maps.LayerData} LayerData instance
         */
        LayerData.create = function create(properties) {
            return new LayerData(properties);
        };

        /**
         * Encodes the specified LayerData message. Does not implicitly {@link lpp_maps.LayerData.verify|verify} messages.
         * @function encode
         * @memberof lpp_maps.LayerData
         * @static
         * @param {lpp_maps.ILayerData} message LayerData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LayerData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.layers != null && message.layers.length)
                for (let i = 0; i < message.layers.length; ++i)
                    $root.lpp_maps.Layer.encode(message.layers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LayerData message, length delimited. Does not implicitly {@link lpp_maps.LayerData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lpp_maps.LayerData
         * @static
         * @param {lpp_maps.ILayerData} message LayerData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LayerData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LayerData message from the specified reader or buffer.
         * @function decode
         * @memberof lpp_maps.LayerData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lpp_maps.LayerData} LayerData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LayerData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lpp_maps.LayerData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.layers && message.layers.length))
                            message.layers = [];
                        message.layers.push($root.lpp_maps.Layer.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LayerData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lpp_maps.LayerData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lpp_maps.LayerData} LayerData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LayerData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LayerData message.
         * @function verify
         * @memberof lpp_maps.LayerData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LayerData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.layers != null && message.hasOwnProperty("layers")) {
                if (!Array.isArray(message.layers))
                    return "layers: array expected";
                for (let i = 0; i < message.layers.length; ++i) {
                    let error = $root.lpp_maps.Layer.verify(message.layers[i]);
                    if (error)
                        return "layers." + error;
                }
            }
            return null;
        };

        /**
         * Creates a LayerData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lpp_maps.LayerData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lpp_maps.LayerData} LayerData
         */
        LayerData.fromObject = function fromObject(object) {
            if (object instanceof $root.lpp_maps.LayerData)
                return object;
            let message = new $root.lpp_maps.LayerData();
            if (object.layers) {
                if (!Array.isArray(object.layers))
                    throw TypeError(".lpp_maps.LayerData.layers: array expected");
                message.layers = [];
                for (let i = 0; i < object.layers.length; ++i) {
                    if (typeof object.layers[i] !== "object")
                        throw TypeError(".lpp_maps.LayerData.layers: object expected");
                    message.layers[i] = $root.lpp_maps.Layer.fromObject(object.layers[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a LayerData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lpp_maps.LayerData
         * @static
         * @param {lpp_maps.LayerData} message LayerData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LayerData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.layers = [];
            if (message.layers && message.layers.length) {
                object.layers = [];
                for (let j = 0; j < message.layers.length; ++j)
                    object.layers[j] = $root.lpp_maps.Layer.toObject(message.layers[j], options);
            }
            return object;
        };

        /**
         * Converts this LayerData to JSON.
         * @function toJSON
         * @memberof lpp_maps.LayerData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LayerData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LayerData
         * @function getTypeUrl
         * @memberof lpp_maps.LayerData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LayerData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/lpp_maps.LayerData";
        };

        return LayerData;
    })();

    lpp_maps.Layer = (function() {

        /**
         * Properties of a Layer.
         * @memberof lpp_maps
         * @interface ILayer
         * @property {string|null} [id] Layer id
         * @property {string|null} [label] Layer label
         * @property {string|null} [shortLabel] Layer shortLabel
         * @property {lpp_types.IColor|null} [color] Layer color
         * @property {Array.<lpp_maps.ILineLayer>|null} [lines] Layer lines
         * @property {Array.<lpp_maps.IMarkerLayer>|null} [markers] Layer markers
         * @property {Array.<lpp_maps.IPolygonLayer>|null} [polygons] Layer polygons
         * @property {lpp_maps.MarkerLayerIcon|null} [legendIcon] Layer legendIcon
         * @property {lpp_maps.LayerType|null} [type] Layer type
         */

        /**
         * Constructs a new Layer.
         * @memberof lpp_maps
         * @classdesc Represents a Layer.
         * @implements ILayer
         * @constructor
         * @param {lpp_maps.ILayer=} [properties] Properties to set
         */
        function Layer(properties) {
            this.lines = [];
            this.markers = [];
            this.polygons = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Layer id.
         * @member {string} id
         * @memberof lpp_maps.Layer
         * @instance
         */
        Layer.prototype.id = "";

        /**
         * Layer label.
         * @member {string} label
         * @memberof lpp_maps.Layer
         * @instance
         */
        Layer.prototype.label = "";

        /**
         * Layer shortLabel.
         * @member {string} shortLabel
         * @memberof lpp_maps.Layer
         * @instance
         */
        Layer.prototype.shortLabel = "";

        /**
         * Layer color.
         * @member {lpp_types.IColor|null|undefined} color
         * @memberof lpp_maps.Layer
         * @instance
         */
        Layer.prototype.color = null;

        /**
         * Layer lines.
         * @member {Array.<lpp_maps.ILineLayer>} lines
         * @memberof lpp_maps.Layer
         * @instance
         */
        Layer.prototype.lines = $util.emptyArray;

        /**
         * Layer markers.
         * @member {Array.<lpp_maps.IMarkerLayer>} markers
         * @memberof lpp_maps.Layer
         * @instance
         */
        Layer.prototype.markers = $util.emptyArray;

        /**
         * Layer polygons.
         * @member {Array.<lpp_maps.IPolygonLayer>} polygons
         * @memberof lpp_maps.Layer
         * @instance
         */
        Layer.prototype.polygons = $util.emptyArray;

        /**
         * Layer legendIcon.
         * @member {lpp_maps.MarkerLayerIcon} legendIcon
         * @memberof lpp_maps.Layer
         * @instance
         */
        Layer.prototype.legendIcon = 0;

        /**
         * Layer type.
         * @member {lpp_maps.LayerType} type
         * @memberof lpp_maps.Layer
         * @instance
         */
        Layer.prototype.type = 0;

        /**
         * Creates a new Layer instance using the specified properties.
         * @function create
         * @memberof lpp_maps.Layer
         * @static
         * @param {lpp_maps.ILayer=} [properties] Properties to set
         * @returns {lpp_maps.Layer} Layer instance
         */
        Layer.create = function create(properties) {
            return new Layer(properties);
        };

        /**
         * Encodes the specified Layer message. Does not implicitly {@link lpp_maps.Layer.verify|verify} messages.
         * @function encode
         * @memberof lpp_maps.Layer
         * @static
         * @param {lpp_maps.ILayer} message Layer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Layer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.lines != null && message.lines.length)
                for (let i = 0; i < message.lines.length; ++i)
                    $root.lpp_maps.LineLayer.encode(message.lines[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.markers != null && message.markers.length)
                for (let i = 0; i < message.markers.length; ++i)
                    $root.lpp_maps.MarkerLayer.encode(message.markers[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.polygons != null && message.polygons.length)
                for (let i = 0; i < message.polygons.length; ++i)
                    $root.lpp_maps.PolygonLayer.encode(message.polygons[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                $root.lpp_types.Color.encode(message.color, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.shortLabel != null && Object.hasOwnProperty.call(message, "shortLabel"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.shortLabel);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.type);
            if (message.legendIcon != null && Object.hasOwnProperty.call(message, "legendIcon"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.legendIcon);
            return writer;
        };

        /**
         * Encodes the specified Layer message, length delimited. Does not implicitly {@link lpp_maps.Layer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lpp_maps.Layer
         * @static
         * @param {lpp_maps.ILayer} message Layer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Layer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Layer message from the specified reader or buffer.
         * @function decode
         * @memberof lpp_maps.Layer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lpp_maps.Layer} Layer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Layer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lpp_maps.Layer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.label = reader.string();
                        break;
                    }
                case 8: {
                        message.shortLabel = reader.string();
                        break;
                    }
                case 7: {
                        message.color = $root.lpp_types.Color.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        if (!(message.lines && message.lines.length))
                            message.lines = [];
                        message.lines.push($root.lpp_maps.LineLayer.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        if (!(message.markers && message.markers.length))
                            message.markers = [];
                        message.markers.push($root.lpp_maps.MarkerLayer.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        if (!(message.polygons && message.polygons.length))
                            message.polygons = [];
                        message.polygons.push($root.lpp_maps.PolygonLayer.decode(reader, reader.uint32()));
                        break;
                    }
                case 11: {
                        message.legendIcon = reader.int32();
                        break;
                    }
                case 10: {
                        message.type = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Layer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lpp_maps.Layer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lpp_maps.Layer} Layer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Layer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Layer message.
         * @function verify
         * @memberof lpp_maps.Layer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Layer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.shortLabel != null && message.hasOwnProperty("shortLabel"))
                if (!$util.isString(message.shortLabel))
                    return "shortLabel: string expected";
            if (message.color != null && message.hasOwnProperty("color")) {
                let error = $root.lpp_types.Color.verify(message.color);
                if (error)
                    return "color." + error;
            }
            if (message.lines != null && message.hasOwnProperty("lines")) {
                if (!Array.isArray(message.lines))
                    return "lines: array expected";
                for (let i = 0; i < message.lines.length; ++i) {
                    let error = $root.lpp_maps.LineLayer.verify(message.lines[i]);
                    if (error)
                        return "lines." + error;
                }
            }
            if (message.markers != null && message.hasOwnProperty("markers")) {
                if (!Array.isArray(message.markers))
                    return "markers: array expected";
                for (let i = 0; i < message.markers.length; ++i) {
                    let error = $root.lpp_maps.MarkerLayer.verify(message.markers[i]);
                    if (error)
                        return "markers." + error;
                }
            }
            if (message.polygons != null && message.hasOwnProperty("polygons")) {
                if (!Array.isArray(message.polygons))
                    return "polygons: array expected";
                for (let i = 0; i < message.polygons.length; ++i) {
                    let error = $root.lpp_maps.PolygonLayer.verify(message.polygons[i]);
                    if (error)
                        return "polygons." + error;
                }
            }
            if (message.legendIcon != null && message.hasOwnProperty("legendIcon"))
                switch (message.legendIcon) {
                default:
                    return "legendIcon: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                    break;
                }
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a Layer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lpp_maps.Layer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lpp_maps.Layer} Layer
         */
        Layer.fromObject = function fromObject(object) {
            if (object instanceof $root.lpp_maps.Layer)
                return object;
            let message = new $root.lpp_maps.Layer();
            if (object.id != null)
                message.id = String(object.id);
            if (object.label != null)
                message.label = String(object.label);
            if (object.shortLabel != null)
                message.shortLabel = String(object.shortLabel);
            if (object.color != null) {
                if (typeof object.color !== "object")
                    throw TypeError(".lpp_maps.Layer.color: object expected");
                message.color = $root.lpp_types.Color.fromObject(object.color);
            }
            if (object.lines) {
                if (!Array.isArray(object.lines))
                    throw TypeError(".lpp_maps.Layer.lines: array expected");
                message.lines = [];
                for (let i = 0; i < object.lines.length; ++i) {
                    if (typeof object.lines[i] !== "object")
                        throw TypeError(".lpp_maps.Layer.lines: object expected");
                    message.lines[i] = $root.lpp_maps.LineLayer.fromObject(object.lines[i]);
                }
            }
            if (object.markers) {
                if (!Array.isArray(object.markers))
                    throw TypeError(".lpp_maps.Layer.markers: array expected");
                message.markers = [];
                for (let i = 0; i < object.markers.length; ++i) {
                    if (typeof object.markers[i] !== "object")
                        throw TypeError(".lpp_maps.Layer.markers: object expected");
                    message.markers[i] = $root.lpp_maps.MarkerLayer.fromObject(object.markers[i]);
                }
            }
            if (object.polygons) {
                if (!Array.isArray(object.polygons))
                    throw TypeError(".lpp_maps.Layer.polygons: array expected");
                message.polygons = [];
                for (let i = 0; i < object.polygons.length; ++i) {
                    if (typeof object.polygons[i] !== "object")
                        throw TypeError(".lpp_maps.Layer.polygons: object expected");
                    message.polygons[i] = $root.lpp_maps.PolygonLayer.fromObject(object.polygons[i]);
                }
            }
            switch (object.legendIcon) {
            default:
                if (typeof object.legendIcon === "number") {
                    message.legendIcon = object.legendIcon;
                    break;
                }
                break;
            case "postajalisce":
            case 0:
                message.legendIcon = 0;
                break;
            case "potniski_center_lpp":
            case 1:
                message.legendIcon = 1;
                break;
            case "prodajno_mesto_urbane":
            case 2:
                message.legendIcon = 2;
                break;
            case "urbanomat":
            case 3:
                message.legendIcon = 3;
                break;
            case "div_icon":
            case 4:
                message.legendIcon = 4;
                break;
            case "klinko":
            case 5:
                message.legendIcon = 5;
                break;
            case "obmocja":
            case 6:
                message.legendIcon = 6;
                break;
            case "p_plus_r":
            case 7:
                message.legendIcon = 7;
                break;
            case "pokopalisce":
            case 8:
                message.legendIcon = 8;
                break;
            case "posta":
            case 9:
                message.legendIcon = 9;
                break;
            case "petrol":
            case 10:
                message.legendIcon = 10;
                break;
            case "radij_300m":
            case 11:
                message.legendIcon = 11;
                break;
            case "radij_500m":
            case 12:
                message.legendIcon = 12;
                break;
            case "bus":
            case 13:
                message.legendIcon = 13;
                break;
            }
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "proga":
            case 0:
                message.type = 0;
                break;
            case "other":
            case 1:
                message.type = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a Layer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lpp_maps.Layer
         * @static
         * @param {lpp_maps.Layer} message Layer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Layer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.lines = [];
                object.markers = [];
                object.polygons = [];
            }
            if (options.defaults) {
                object.id = "";
                object.label = "";
                object.color = null;
                object.shortLabel = "";
                object.type = options.enums === String ? "proga" : 0;
                object.legendIcon = options.enums === String ? "postajalisce" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.lines && message.lines.length) {
                object.lines = [];
                for (let j = 0; j < message.lines.length; ++j)
                    object.lines[j] = $root.lpp_maps.LineLayer.toObject(message.lines[j], options);
            }
            if (message.markers && message.markers.length) {
                object.markers = [];
                for (let j = 0; j < message.markers.length; ++j)
                    object.markers[j] = $root.lpp_maps.MarkerLayer.toObject(message.markers[j], options);
            }
            if (message.polygons && message.polygons.length) {
                object.polygons = [];
                for (let j = 0; j < message.polygons.length; ++j)
                    object.polygons[j] = $root.lpp_maps.PolygonLayer.toObject(message.polygons[j], options);
            }
            if (message.color != null && message.hasOwnProperty("color"))
                object.color = $root.lpp_types.Color.toObject(message.color, options);
            if (message.shortLabel != null && message.hasOwnProperty("shortLabel"))
                object.shortLabel = message.shortLabel;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.lpp_maps.LayerType[message.type] === undefined ? message.type : $root.lpp_maps.LayerType[message.type] : message.type;
            if (message.legendIcon != null && message.hasOwnProperty("legendIcon"))
                object.legendIcon = options.enums === String ? $root.lpp_maps.MarkerLayerIcon[message.legendIcon] === undefined ? message.legendIcon : $root.lpp_maps.MarkerLayerIcon[message.legendIcon] : message.legendIcon;
            return object;
        };

        /**
         * Converts this Layer to JSON.
         * @function toJSON
         * @memberof lpp_maps.Layer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Layer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Layer
         * @function getTypeUrl
         * @memberof lpp_maps.Layer
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Layer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/lpp_maps.Layer";
        };

        return Layer;
    })();

    lpp_maps.LineLayer = (function() {

        /**
         * Properties of a LineLayer.
         * @memberof lpp_maps
         * @interface ILineLayer
         * @property {string|null} [id] LineLayer id
         * @property {string|null} [label] LineLayer label
         * @property {string|null} [shortLabel] LineLayer shortLabel
         * @property {lpp_types.IColor|null} [color] LineLayer color
         * @property {Array.<lpp_types.ILatLng>|null} [points] LineLayer points
         * @property {string|null} [cap] LineLayer cap
         * @property {string|null} [join] LineLayer join
         * @property {Array.<number>|null} [dashArray] LineLayer dashArray
         * @property {number|null} [weight] LineLayer weight
         */

        /**
         * Constructs a new LineLayer.
         * @memberof lpp_maps
         * @classdesc Represents a LineLayer.
         * @implements ILineLayer
         * @constructor
         * @param {lpp_maps.ILineLayer=} [properties] Properties to set
         */
        function LineLayer(properties) {
            this.points = [];
            this.dashArray = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LineLayer id.
         * @member {string} id
         * @memberof lpp_maps.LineLayer
         * @instance
         */
        LineLayer.prototype.id = "";

        /**
         * LineLayer label.
         * @member {string} label
         * @memberof lpp_maps.LineLayer
         * @instance
         */
        LineLayer.prototype.label = "";

        /**
         * LineLayer shortLabel.
         * @member {string} shortLabel
         * @memberof lpp_maps.LineLayer
         * @instance
         */
        LineLayer.prototype.shortLabel = "";

        /**
         * LineLayer color.
         * @member {lpp_types.IColor|null|undefined} color
         * @memberof lpp_maps.LineLayer
         * @instance
         */
        LineLayer.prototype.color = null;

        /**
         * LineLayer points.
         * @member {Array.<lpp_types.ILatLng>} points
         * @memberof lpp_maps.LineLayer
         * @instance
         */
        LineLayer.prototype.points = $util.emptyArray;

        /**
         * LineLayer cap.
         * @member {string} cap
         * @memberof lpp_maps.LineLayer
         * @instance
         */
        LineLayer.prototype.cap = "";

        /**
         * LineLayer join.
         * @member {string} join
         * @memberof lpp_maps.LineLayer
         * @instance
         */
        LineLayer.prototype.join = "";

        /**
         * LineLayer dashArray.
         * @member {Array.<number>} dashArray
         * @memberof lpp_maps.LineLayer
         * @instance
         */
        LineLayer.prototype.dashArray = $util.emptyArray;

        /**
         * LineLayer weight.
         * @member {number} weight
         * @memberof lpp_maps.LineLayer
         * @instance
         */
        LineLayer.prototype.weight = 0;

        /**
         * Creates a new LineLayer instance using the specified properties.
         * @function create
         * @memberof lpp_maps.LineLayer
         * @static
         * @param {lpp_maps.ILineLayer=} [properties] Properties to set
         * @returns {lpp_maps.LineLayer} LineLayer instance
         */
        LineLayer.create = function create(properties) {
            return new LineLayer(properties);
        };

        /**
         * Encodes the specified LineLayer message. Does not implicitly {@link lpp_maps.LineLayer.verify|verify} messages.
         * @function encode
         * @memberof lpp_maps.LineLayer
         * @static
         * @param {lpp_maps.ILineLayer} message LineLayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LineLayer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                $root.lpp_types.Color.encode(message.color, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.points != null && message.points.length)
                for (let i = 0; i < message.points.length; ++i)
                    $root.lpp_types.LatLng.encode(message.points[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.shortLabel != null && Object.hasOwnProperty.call(message, "shortLabel"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.shortLabel);
            if (message.cap != null && Object.hasOwnProperty.call(message, "cap"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.cap);
            if (message.join != null && Object.hasOwnProperty.call(message, "join"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.join);
            if (message.dashArray != null && message.dashArray.length) {
                writer.uint32(/* id 8, wireType 2 =*/66).fork();
                for (let i = 0; i < message.dashArray.length; ++i)
                    writer.int32(message.dashArray[i]);
                writer.ldelim();
            }
            if (message.weight != null && Object.hasOwnProperty.call(message, "weight"))
                writer.uint32(/* id 9, wireType 1 =*/73).double(message.weight);
            return writer;
        };

        /**
         * Encodes the specified LineLayer message, length delimited. Does not implicitly {@link lpp_maps.LineLayer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lpp_maps.LineLayer
         * @static
         * @param {lpp_maps.ILineLayer} message LineLayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LineLayer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LineLayer message from the specified reader or buffer.
         * @function decode
         * @memberof lpp_maps.LineLayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lpp_maps.LineLayer} LineLayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LineLayer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lpp_maps.LineLayer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.label = reader.string();
                        break;
                    }
                case 5: {
                        message.shortLabel = reader.string();
                        break;
                    }
                case 3: {
                        message.color = $root.lpp_types.Color.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        if (!(message.points && message.points.length))
                            message.points = [];
                        message.points.push($root.lpp_types.LatLng.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        message.cap = reader.string();
                        break;
                    }
                case 7: {
                        message.join = reader.string();
                        break;
                    }
                case 8: {
                        if (!(message.dashArray && message.dashArray.length))
                            message.dashArray = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.dashArray.push(reader.int32());
                        } else
                            message.dashArray.push(reader.int32());
                        break;
                    }
                case 9: {
                        message.weight = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LineLayer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lpp_maps.LineLayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lpp_maps.LineLayer} LineLayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LineLayer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LineLayer message.
         * @function verify
         * @memberof lpp_maps.LineLayer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LineLayer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.shortLabel != null && message.hasOwnProperty("shortLabel"))
                if (!$util.isString(message.shortLabel))
                    return "shortLabel: string expected";
            if (message.color != null && message.hasOwnProperty("color")) {
                let error = $root.lpp_types.Color.verify(message.color);
                if (error)
                    return "color." + error;
            }
            if (message.points != null && message.hasOwnProperty("points")) {
                if (!Array.isArray(message.points))
                    return "points: array expected";
                for (let i = 0; i < message.points.length; ++i) {
                    let error = $root.lpp_types.LatLng.verify(message.points[i]);
                    if (error)
                        return "points." + error;
                }
            }
            if (message.cap != null && message.hasOwnProperty("cap"))
                if (!$util.isString(message.cap))
                    return "cap: string expected";
            if (message.join != null && message.hasOwnProperty("join"))
                if (!$util.isString(message.join))
                    return "join: string expected";
            if (message.dashArray != null && message.hasOwnProperty("dashArray")) {
                if (!Array.isArray(message.dashArray))
                    return "dashArray: array expected";
                for (let i = 0; i < message.dashArray.length; ++i)
                    if (!$util.isInteger(message.dashArray[i]))
                        return "dashArray: integer[] expected";
            }
            if (message.weight != null && message.hasOwnProperty("weight"))
                if (typeof message.weight !== "number")
                    return "weight: number expected";
            return null;
        };

        /**
         * Creates a LineLayer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lpp_maps.LineLayer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lpp_maps.LineLayer} LineLayer
         */
        LineLayer.fromObject = function fromObject(object) {
            if (object instanceof $root.lpp_maps.LineLayer)
                return object;
            let message = new $root.lpp_maps.LineLayer();
            if (object.id != null)
                message.id = String(object.id);
            if (object.label != null)
                message.label = String(object.label);
            if (object.shortLabel != null)
                message.shortLabel = String(object.shortLabel);
            if (object.color != null) {
                if (typeof object.color !== "object")
                    throw TypeError(".lpp_maps.LineLayer.color: object expected");
                message.color = $root.lpp_types.Color.fromObject(object.color);
            }
            if (object.points) {
                if (!Array.isArray(object.points))
                    throw TypeError(".lpp_maps.LineLayer.points: array expected");
                message.points = [];
                for (let i = 0; i < object.points.length; ++i) {
                    if (typeof object.points[i] !== "object")
                        throw TypeError(".lpp_maps.LineLayer.points: object expected");
                    message.points[i] = $root.lpp_types.LatLng.fromObject(object.points[i]);
                }
            }
            if (object.cap != null)
                message.cap = String(object.cap);
            if (object.join != null)
                message.join = String(object.join);
            if (object.dashArray) {
                if (!Array.isArray(object.dashArray))
                    throw TypeError(".lpp_maps.LineLayer.dashArray: array expected");
                message.dashArray = [];
                for (let i = 0; i < object.dashArray.length; ++i)
                    message.dashArray[i] = object.dashArray[i] | 0;
            }
            if (object.weight != null)
                message.weight = Number(object.weight);
            return message;
        };

        /**
         * Creates a plain object from a LineLayer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lpp_maps.LineLayer
         * @static
         * @param {lpp_maps.LineLayer} message LineLayer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LineLayer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.points = [];
                object.dashArray = [];
            }
            if (options.defaults) {
                object.id = "";
                object.label = "";
                object.color = null;
                object.shortLabel = "";
                object.cap = "";
                object.join = "";
                object.weight = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.color != null && message.hasOwnProperty("color"))
                object.color = $root.lpp_types.Color.toObject(message.color, options);
            if (message.points && message.points.length) {
                object.points = [];
                for (let j = 0; j < message.points.length; ++j)
                    object.points[j] = $root.lpp_types.LatLng.toObject(message.points[j], options);
            }
            if (message.shortLabel != null && message.hasOwnProperty("shortLabel"))
                object.shortLabel = message.shortLabel;
            if (message.cap != null && message.hasOwnProperty("cap"))
                object.cap = message.cap;
            if (message.join != null && message.hasOwnProperty("join"))
                object.join = message.join;
            if (message.dashArray && message.dashArray.length) {
                object.dashArray = [];
                for (let j = 0; j < message.dashArray.length; ++j)
                    object.dashArray[j] = message.dashArray[j];
            }
            if (message.weight != null && message.hasOwnProperty("weight"))
                object.weight = options.json && !isFinite(message.weight) ? String(message.weight) : message.weight;
            return object;
        };

        /**
         * Converts this LineLayer to JSON.
         * @function toJSON
         * @memberof lpp_maps.LineLayer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LineLayer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LineLayer
         * @function getTypeUrl
         * @memberof lpp_maps.LineLayer
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LineLayer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/lpp_maps.LineLayer";
        };

        return LineLayer;
    })();

    lpp_maps.MarkerLayer = (function() {

        /**
         * Properties of a MarkerLayer.
         * @memberof lpp_maps
         * @interface IMarkerLayer
         * @property {string|null} [id] MarkerLayer id
         * @property {string|null} [label] MarkerLayer label
         * @property {string|null} [description] MarkerLayer description
         * @property {lpp_maps.MarkerLayerIcon|null} [icon] MarkerLayer icon
         * @property {lpp_types.ILatLng|null} [location] MarkerLayer location
         * @property {string|null} [divIcon] MarkerLayer divIcon
         * @property {number|null} [iconSize] MarkerLayer iconSize
         * @property {lpp_maps.MarkerLayerVariant|null} [variant] MarkerLayer variant
         */

        /**
         * Constructs a new MarkerLayer.
         * @memberof lpp_maps
         * @classdesc Represents a MarkerLayer.
         * @implements IMarkerLayer
         * @constructor
         * @param {lpp_maps.IMarkerLayer=} [properties] Properties to set
         */
        function MarkerLayer(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MarkerLayer id.
         * @member {string} id
         * @memberof lpp_maps.MarkerLayer
         * @instance
         */
        MarkerLayer.prototype.id = "";

        /**
         * MarkerLayer label.
         * @member {string} label
         * @memberof lpp_maps.MarkerLayer
         * @instance
         */
        MarkerLayer.prototype.label = "";

        /**
         * MarkerLayer description.
         * @member {string} description
         * @memberof lpp_maps.MarkerLayer
         * @instance
         */
        MarkerLayer.prototype.description = "";

        /**
         * MarkerLayer icon.
         * @member {lpp_maps.MarkerLayerIcon} icon
         * @memberof lpp_maps.MarkerLayer
         * @instance
         */
        MarkerLayer.prototype.icon = 0;

        /**
         * MarkerLayer location.
         * @member {lpp_types.ILatLng|null|undefined} location
         * @memberof lpp_maps.MarkerLayer
         * @instance
         */
        MarkerLayer.prototype.location = null;

        /**
         * MarkerLayer divIcon.
         * @member {string} divIcon
         * @memberof lpp_maps.MarkerLayer
         * @instance
         */
        MarkerLayer.prototype.divIcon = "";

        /**
         * MarkerLayer iconSize.
         * @member {number} iconSize
         * @memberof lpp_maps.MarkerLayer
         * @instance
         */
        MarkerLayer.prototype.iconSize = 0;

        /**
         * MarkerLayer variant.
         * @member {lpp_maps.MarkerLayerVariant} variant
         * @memberof lpp_maps.MarkerLayer
         * @instance
         */
        MarkerLayer.prototype.variant = 0;

        /**
         * Creates a new MarkerLayer instance using the specified properties.
         * @function create
         * @memberof lpp_maps.MarkerLayer
         * @static
         * @param {lpp_maps.IMarkerLayer=} [properties] Properties to set
         * @returns {lpp_maps.MarkerLayer} MarkerLayer instance
         */
        MarkerLayer.create = function create(properties) {
            return new MarkerLayer(properties);
        };

        /**
         * Encodes the specified MarkerLayer message. Does not implicitly {@link lpp_maps.MarkerLayer.verify|verify} messages.
         * @function encode
         * @memberof lpp_maps.MarkerLayer
         * @static
         * @param {lpp_maps.IMarkerLayer} message MarkerLayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MarkerLayer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.icon);
            if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                $root.lpp_types.LatLng.encode(message.location, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.divIcon != null && Object.hasOwnProperty.call(message, "divIcon"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.divIcon);
            if (message.iconSize != null && Object.hasOwnProperty.call(message, "iconSize"))
                writer.uint32(/* id 6, wireType 1 =*/49).double(message.iconSize);
            if (message.variant != null && Object.hasOwnProperty.call(message, "variant"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.variant);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.description);
            return writer;
        };

        /**
         * Encodes the specified MarkerLayer message, length delimited. Does not implicitly {@link lpp_maps.MarkerLayer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lpp_maps.MarkerLayer
         * @static
         * @param {lpp_maps.IMarkerLayer} message MarkerLayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MarkerLayer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MarkerLayer message from the specified reader or buffer.
         * @function decode
         * @memberof lpp_maps.MarkerLayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lpp_maps.MarkerLayer} MarkerLayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MarkerLayer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lpp_maps.MarkerLayer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.label = reader.string();
                        break;
                    }
                case 8: {
                        message.description = reader.string();
                        break;
                    }
                case 3: {
                        message.icon = reader.int32();
                        break;
                    }
                case 4: {
                        message.location = $root.lpp_types.LatLng.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.divIcon = reader.string();
                        break;
                    }
                case 6: {
                        message.iconSize = reader.double();
                        break;
                    }
                case 7: {
                        message.variant = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MarkerLayer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lpp_maps.MarkerLayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lpp_maps.MarkerLayer} MarkerLayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MarkerLayer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MarkerLayer message.
         * @function verify
         * @memberof lpp_maps.MarkerLayer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MarkerLayer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.icon != null && message.hasOwnProperty("icon"))
                switch (message.icon) {
                default:
                    return "icon: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                    break;
                }
            if (message.location != null && message.hasOwnProperty("location")) {
                let error = $root.lpp_types.LatLng.verify(message.location);
                if (error)
                    return "location." + error;
            }
            if (message.divIcon != null && message.hasOwnProperty("divIcon"))
                if (!$util.isString(message.divIcon))
                    return "divIcon: string expected";
            if (message.iconSize != null && message.hasOwnProperty("iconSize"))
                if (typeof message.iconSize !== "number")
                    return "iconSize: number expected";
            if (message.variant != null && message.hasOwnProperty("variant"))
                switch (message.variant) {
                default:
                    return "variant: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a MarkerLayer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lpp_maps.MarkerLayer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lpp_maps.MarkerLayer} MarkerLayer
         */
        MarkerLayer.fromObject = function fromObject(object) {
            if (object instanceof $root.lpp_maps.MarkerLayer)
                return object;
            let message = new $root.lpp_maps.MarkerLayer();
            if (object.id != null)
                message.id = String(object.id);
            if (object.label != null)
                message.label = String(object.label);
            if (object.description != null)
                message.description = String(object.description);
            switch (object.icon) {
            default:
                if (typeof object.icon === "number") {
                    message.icon = object.icon;
                    break;
                }
                break;
            case "postajalisce":
            case 0:
                message.icon = 0;
                break;
            case "potniski_center_lpp":
            case 1:
                message.icon = 1;
                break;
            case "prodajno_mesto_urbane":
            case 2:
                message.icon = 2;
                break;
            case "urbanomat":
            case 3:
                message.icon = 3;
                break;
            case "div_icon":
            case 4:
                message.icon = 4;
                break;
            case "klinko":
            case 5:
                message.icon = 5;
                break;
            case "obmocja":
            case 6:
                message.icon = 6;
                break;
            case "p_plus_r":
            case 7:
                message.icon = 7;
                break;
            case "pokopalisce":
            case 8:
                message.icon = 8;
                break;
            case "posta":
            case 9:
                message.icon = 9;
                break;
            case "petrol":
            case 10:
                message.icon = 10;
                break;
            case "radij_300m":
            case 11:
                message.icon = 11;
                break;
            case "radij_500m":
            case 12:
                message.icon = 12;
                break;
            case "bus":
            case 13:
                message.icon = 13;
                break;
            }
            if (object.location != null) {
                if (typeof object.location !== "object")
                    throw TypeError(".lpp_maps.MarkerLayer.location: object expected");
                message.location = $root.lpp_types.LatLng.fromObject(object.location);
            }
            if (object.divIcon != null)
                message.divIcon = String(object.divIcon);
            if (object.iconSize != null)
                message.iconSize = Number(object.iconSize);
            switch (object.variant) {
            default:
                if (typeof object.variant === "number") {
                    message.variant = object.variant;
                    break;
                }
                break;
            case "iz_centra":
            case 0:
                message.variant = 0;
                break;
            case "proti_centru":
            case 1:
                message.variant = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a MarkerLayer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lpp_maps.MarkerLayer
         * @static
         * @param {lpp_maps.MarkerLayer} message MarkerLayer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MarkerLayer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.label = "";
                object.icon = options.enums === String ? "postajalisce" : 0;
                object.location = null;
                object.divIcon = "";
                object.iconSize = 0;
                object.variant = options.enums === String ? "iz_centra" : 0;
                object.description = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.icon != null && message.hasOwnProperty("icon"))
                object.icon = options.enums === String ? $root.lpp_maps.MarkerLayerIcon[message.icon] === undefined ? message.icon : $root.lpp_maps.MarkerLayerIcon[message.icon] : message.icon;
            if (message.location != null && message.hasOwnProperty("location"))
                object.location = $root.lpp_types.LatLng.toObject(message.location, options);
            if (message.divIcon != null && message.hasOwnProperty("divIcon"))
                object.divIcon = message.divIcon;
            if (message.iconSize != null && message.hasOwnProperty("iconSize"))
                object.iconSize = options.json && !isFinite(message.iconSize) ? String(message.iconSize) : message.iconSize;
            if (message.variant != null && message.hasOwnProperty("variant"))
                object.variant = options.enums === String ? $root.lpp_maps.MarkerLayerVariant[message.variant] === undefined ? message.variant : $root.lpp_maps.MarkerLayerVariant[message.variant] : message.variant;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        /**
         * Converts this MarkerLayer to JSON.
         * @function toJSON
         * @memberof lpp_maps.MarkerLayer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MarkerLayer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MarkerLayer
         * @function getTypeUrl
         * @memberof lpp_maps.MarkerLayer
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MarkerLayer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/lpp_maps.MarkerLayer";
        };

        return MarkerLayer;
    })();

    lpp_maps.PolygonLayer = (function() {

        /**
         * Properties of a PolygonLayer.
         * @memberof lpp_maps
         * @interface IPolygonLayer
         * @property {string|null} [id] PolygonLayer id
         * @property {string|null} [label] PolygonLayer label
         * @property {lpp_types.IColor|null} [fillColor] PolygonLayer fillColor
         * @property {lpp_types.IColor|null} [borderColor] PolygonLayer borderColor
         * @property {Array.<lpp_types.ILatLng>|null} [points] PolygonLayer points
         * @property {string|null} [cap] PolygonLayer cap
         * @property {string|null} [join] PolygonLayer join
         * @property {Array.<number>|null} [dashArray] PolygonLayer dashArray
         * @property {number|null} [borderWidth] PolygonLayer borderWidth
         */

        /**
         * Constructs a new PolygonLayer.
         * @memberof lpp_maps
         * @classdesc Represents a PolygonLayer.
         * @implements IPolygonLayer
         * @constructor
         * @param {lpp_maps.IPolygonLayer=} [properties] Properties to set
         */
        function PolygonLayer(properties) {
            this.points = [];
            this.dashArray = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PolygonLayer id.
         * @member {string} id
         * @memberof lpp_maps.PolygonLayer
         * @instance
         */
        PolygonLayer.prototype.id = "";

        /**
         * PolygonLayer label.
         * @member {string} label
         * @memberof lpp_maps.PolygonLayer
         * @instance
         */
        PolygonLayer.prototype.label = "";

        /**
         * PolygonLayer fillColor.
         * @member {lpp_types.IColor|null|undefined} fillColor
         * @memberof lpp_maps.PolygonLayer
         * @instance
         */
        PolygonLayer.prototype.fillColor = null;

        /**
         * PolygonLayer borderColor.
         * @member {lpp_types.IColor|null|undefined} borderColor
         * @memberof lpp_maps.PolygonLayer
         * @instance
         */
        PolygonLayer.prototype.borderColor = null;

        /**
         * PolygonLayer points.
         * @member {Array.<lpp_types.ILatLng>} points
         * @memberof lpp_maps.PolygonLayer
         * @instance
         */
        PolygonLayer.prototype.points = $util.emptyArray;

        /**
         * PolygonLayer cap.
         * @member {string} cap
         * @memberof lpp_maps.PolygonLayer
         * @instance
         */
        PolygonLayer.prototype.cap = "";

        /**
         * PolygonLayer join.
         * @member {string} join
         * @memberof lpp_maps.PolygonLayer
         * @instance
         */
        PolygonLayer.prototype.join = "";

        /**
         * PolygonLayer dashArray.
         * @member {Array.<number>} dashArray
         * @memberof lpp_maps.PolygonLayer
         * @instance
         */
        PolygonLayer.prototype.dashArray = $util.emptyArray;

        /**
         * PolygonLayer borderWidth.
         * @member {number} borderWidth
         * @memberof lpp_maps.PolygonLayer
         * @instance
         */
        PolygonLayer.prototype.borderWidth = 0;

        /**
         * Creates a new PolygonLayer instance using the specified properties.
         * @function create
         * @memberof lpp_maps.PolygonLayer
         * @static
         * @param {lpp_maps.IPolygonLayer=} [properties] Properties to set
         * @returns {lpp_maps.PolygonLayer} PolygonLayer instance
         */
        PolygonLayer.create = function create(properties) {
            return new PolygonLayer(properties);
        };

        /**
         * Encodes the specified PolygonLayer message. Does not implicitly {@link lpp_maps.PolygonLayer.verify|verify} messages.
         * @function encode
         * @memberof lpp_maps.PolygonLayer
         * @static
         * @param {lpp_maps.IPolygonLayer} message PolygonLayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PolygonLayer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.fillColor != null && Object.hasOwnProperty.call(message, "fillColor"))
                $root.lpp_types.Color.encode(message.fillColor, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.borderColor != null && Object.hasOwnProperty.call(message, "borderColor"))
                $root.lpp_types.Color.encode(message.borderColor, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.points != null && message.points.length)
                for (let i = 0; i < message.points.length; ++i)
                    $root.lpp_types.LatLng.encode(message.points[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.cap != null && Object.hasOwnProperty.call(message, "cap"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.cap);
            if (message.join != null && Object.hasOwnProperty.call(message, "join"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.join);
            if (message.dashArray != null && message.dashArray.length) {
                writer.uint32(/* id 8, wireType 2 =*/66).fork();
                for (let i = 0; i < message.dashArray.length; ++i)
                    writer.int32(message.dashArray[i]);
                writer.ldelim();
            }
            if (message.borderWidth != null && Object.hasOwnProperty.call(message, "borderWidth"))
                writer.uint32(/* id 9, wireType 1 =*/73).double(message.borderWidth);
            return writer;
        };

        /**
         * Encodes the specified PolygonLayer message, length delimited. Does not implicitly {@link lpp_maps.PolygonLayer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lpp_maps.PolygonLayer
         * @static
         * @param {lpp_maps.IPolygonLayer} message PolygonLayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PolygonLayer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PolygonLayer message from the specified reader or buffer.
         * @function decode
         * @memberof lpp_maps.PolygonLayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lpp_maps.PolygonLayer} PolygonLayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PolygonLayer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lpp_maps.PolygonLayer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.label = reader.string();
                        break;
                    }
                case 3: {
                        message.fillColor = $root.lpp_types.Color.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.borderColor = $root.lpp_types.Color.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        if (!(message.points && message.points.length))
                            message.points = [];
                        message.points.push($root.lpp_types.LatLng.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        message.cap = reader.string();
                        break;
                    }
                case 7: {
                        message.join = reader.string();
                        break;
                    }
                case 8: {
                        if (!(message.dashArray && message.dashArray.length))
                            message.dashArray = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.dashArray.push(reader.int32());
                        } else
                            message.dashArray.push(reader.int32());
                        break;
                    }
                case 9: {
                        message.borderWidth = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PolygonLayer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lpp_maps.PolygonLayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lpp_maps.PolygonLayer} PolygonLayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PolygonLayer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PolygonLayer message.
         * @function verify
         * @memberof lpp_maps.PolygonLayer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PolygonLayer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.fillColor != null && message.hasOwnProperty("fillColor")) {
                let error = $root.lpp_types.Color.verify(message.fillColor);
                if (error)
                    return "fillColor." + error;
            }
            if (message.borderColor != null && message.hasOwnProperty("borderColor")) {
                let error = $root.lpp_types.Color.verify(message.borderColor);
                if (error)
                    return "borderColor." + error;
            }
            if (message.points != null && message.hasOwnProperty("points")) {
                if (!Array.isArray(message.points))
                    return "points: array expected";
                for (let i = 0; i < message.points.length; ++i) {
                    let error = $root.lpp_types.LatLng.verify(message.points[i]);
                    if (error)
                        return "points." + error;
                }
            }
            if (message.cap != null && message.hasOwnProperty("cap"))
                if (!$util.isString(message.cap))
                    return "cap: string expected";
            if (message.join != null && message.hasOwnProperty("join"))
                if (!$util.isString(message.join))
                    return "join: string expected";
            if (message.dashArray != null && message.hasOwnProperty("dashArray")) {
                if (!Array.isArray(message.dashArray))
                    return "dashArray: array expected";
                for (let i = 0; i < message.dashArray.length; ++i)
                    if (!$util.isInteger(message.dashArray[i]))
                        return "dashArray: integer[] expected";
            }
            if (message.borderWidth != null && message.hasOwnProperty("borderWidth"))
                if (typeof message.borderWidth !== "number")
                    return "borderWidth: number expected";
            return null;
        };

        /**
         * Creates a PolygonLayer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lpp_maps.PolygonLayer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lpp_maps.PolygonLayer} PolygonLayer
         */
        PolygonLayer.fromObject = function fromObject(object) {
            if (object instanceof $root.lpp_maps.PolygonLayer)
                return object;
            let message = new $root.lpp_maps.PolygonLayer();
            if (object.id != null)
                message.id = String(object.id);
            if (object.label != null)
                message.label = String(object.label);
            if (object.fillColor != null) {
                if (typeof object.fillColor !== "object")
                    throw TypeError(".lpp_maps.PolygonLayer.fillColor: object expected");
                message.fillColor = $root.lpp_types.Color.fromObject(object.fillColor);
            }
            if (object.borderColor != null) {
                if (typeof object.borderColor !== "object")
                    throw TypeError(".lpp_maps.PolygonLayer.borderColor: object expected");
                message.borderColor = $root.lpp_types.Color.fromObject(object.borderColor);
            }
            if (object.points) {
                if (!Array.isArray(object.points))
                    throw TypeError(".lpp_maps.PolygonLayer.points: array expected");
                message.points = [];
                for (let i = 0; i < object.points.length; ++i) {
                    if (typeof object.points[i] !== "object")
                        throw TypeError(".lpp_maps.PolygonLayer.points: object expected");
                    message.points[i] = $root.lpp_types.LatLng.fromObject(object.points[i]);
                }
            }
            if (object.cap != null)
                message.cap = String(object.cap);
            if (object.join != null)
                message.join = String(object.join);
            if (object.dashArray) {
                if (!Array.isArray(object.dashArray))
                    throw TypeError(".lpp_maps.PolygonLayer.dashArray: array expected");
                message.dashArray = [];
                for (let i = 0; i < object.dashArray.length; ++i)
                    message.dashArray[i] = object.dashArray[i] | 0;
            }
            if (object.borderWidth != null)
                message.borderWidth = Number(object.borderWidth);
            return message;
        };

        /**
         * Creates a plain object from a PolygonLayer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lpp_maps.PolygonLayer
         * @static
         * @param {lpp_maps.PolygonLayer} message PolygonLayer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PolygonLayer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.points = [];
                object.dashArray = [];
            }
            if (options.defaults) {
                object.id = "";
                object.label = "";
                object.fillColor = null;
                object.borderColor = null;
                object.cap = "";
                object.join = "";
                object.borderWidth = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.fillColor != null && message.hasOwnProperty("fillColor"))
                object.fillColor = $root.lpp_types.Color.toObject(message.fillColor, options);
            if (message.borderColor != null && message.hasOwnProperty("borderColor"))
                object.borderColor = $root.lpp_types.Color.toObject(message.borderColor, options);
            if (message.points && message.points.length) {
                object.points = [];
                for (let j = 0; j < message.points.length; ++j)
                    object.points[j] = $root.lpp_types.LatLng.toObject(message.points[j], options);
            }
            if (message.cap != null && message.hasOwnProperty("cap"))
                object.cap = message.cap;
            if (message.join != null && message.hasOwnProperty("join"))
                object.join = message.join;
            if (message.dashArray && message.dashArray.length) {
                object.dashArray = [];
                for (let j = 0; j < message.dashArray.length; ++j)
                    object.dashArray[j] = message.dashArray[j];
            }
            if (message.borderWidth != null && message.hasOwnProperty("borderWidth"))
                object.borderWidth = options.json && !isFinite(message.borderWidth) ? String(message.borderWidth) : message.borderWidth;
            return object;
        };

        /**
         * Converts this PolygonLayer to JSON.
         * @function toJSON
         * @memberof lpp_maps.PolygonLayer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PolygonLayer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PolygonLayer
         * @function getTypeUrl
         * @memberof lpp_maps.PolygonLayer
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PolygonLayer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/lpp_maps.PolygonLayer";
        };

        return PolygonLayer;
    })();

    /**
     * MarkerLayerVariant enum.
     * @name lpp_maps.MarkerLayerVariant
     * @enum {number}
     * @property {number} iz_centra=0 iz_centra value
     * @property {number} proti_centru=1 proti_centru value
     */
    lpp_maps.MarkerLayerVariant = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "iz_centra"] = 0;
        values[valuesById[1] = "proti_centru"] = 1;
        return values;
    })();

    /**
     * MarkerLayerIcon enum.
     * @name lpp_maps.MarkerLayerIcon
     * @enum {number}
     * @property {number} postajalisce=0 postajalisce value
     * @property {number} potniski_center_lpp=1 potniski_center_lpp value
     * @property {number} prodajno_mesto_urbane=2 prodajno_mesto_urbane value
     * @property {number} urbanomat=3 urbanomat value
     * @property {number} div_icon=4 div_icon value
     * @property {number} klinko=5 klinko value
     * @property {number} obmocja=6 obmocja value
     * @property {number} p_plus_r=7 p_plus_r value
     * @property {number} pokopalisce=8 pokopalisce value
     * @property {number} posta=9 posta value
     * @property {number} petrol=10 petrol value
     * @property {number} radij_300m=11 radij_300m value
     * @property {number} radij_500m=12 radij_500m value
     * @property {number} bus=13 bus value
     */
    lpp_maps.MarkerLayerIcon = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "postajalisce"] = 0;
        values[valuesById[1] = "potniski_center_lpp"] = 1;
        values[valuesById[2] = "prodajno_mesto_urbane"] = 2;
        values[valuesById[3] = "urbanomat"] = 3;
        values[valuesById[4] = "div_icon"] = 4;
        values[valuesById[5] = "klinko"] = 5;
        values[valuesById[6] = "obmocja"] = 6;
        values[valuesById[7] = "p_plus_r"] = 7;
        values[valuesById[8] = "pokopalisce"] = 8;
        values[valuesById[9] = "posta"] = 9;
        values[valuesById[10] = "petrol"] = 10;
        values[valuesById[11] = "radij_300m"] = 11;
        values[valuesById[12] = "radij_500m"] = 12;
        values[valuesById[13] = "bus"] = 13;
        return values;
    })();

    /**
     * LayerType enum.
     * @name lpp_maps.LayerType
     * @enum {number}
     * @property {number} proga=0 proga value
     * @property {number} other=1 other value
     */
    lpp_maps.LayerType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "proga"] = 0;
        values[valuesById[1] = "other"] = 1;
        return values;
    })();

    return lpp_maps;
})();

export const lpp_proge = $root.lpp_proge = (() => {

    /**
     * Namespace lpp_proge.
     * @exports lpp_proge
     * @namespace
     */
    const lpp_proge = {};

    lpp_proge.GetMagicLink = (function() {

        /**
         * Properties of a GetMagicLink.
         * @memberof lpp_proge
         * @interface IGetMagicLink
         * @property {string|null} [mail] GetMagicLink mail
         */

        /**
         * Constructs a new GetMagicLink.
         * @memberof lpp_proge
         * @classdesc Represents a GetMagicLink.
         * @implements IGetMagicLink
         * @constructor
         * @param {lpp_proge.IGetMagicLink=} [properties] Properties to set
         */
        function GetMagicLink(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMagicLink mail.
         * @member {string} mail
         * @memberof lpp_proge.GetMagicLink
         * @instance
         */
        GetMagicLink.prototype.mail = "";

        /**
         * Creates a new GetMagicLink instance using the specified properties.
         * @function create
         * @memberof lpp_proge.GetMagicLink
         * @static
         * @param {lpp_proge.IGetMagicLink=} [properties] Properties to set
         * @returns {lpp_proge.GetMagicLink} GetMagicLink instance
         */
        GetMagicLink.create = function create(properties) {
            return new GetMagicLink(properties);
        };

        /**
         * Encodes the specified GetMagicLink message. Does not implicitly {@link lpp_proge.GetMagicLink.verify|verify} messages.
         * @function encode
         * @memberof lpp_proge.GetMagicLink
         * @static
         * @param {lpp_proge.IGetMagicLink} message GetMagicLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMagicLink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mail != null && Object.hasOwnProperty.call(message, "mail"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.mail);
            return writer;
        };

        /**
         * Encodes the specified GetMagicLink message, length delimited. Does not implicitly {@link lpp_proge.GetMagicLink.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lpp_proge.GetMagicLink
         * @static
         * @param {lpp_proge.IGetMagicLink} message GetMagicLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMagicLink.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMagicLink message from the specified reader or buffer.
         * @function decode
         * @memberof lpp_proge.GetMagicLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lpp_proge.GetMagicLink} GetMagicLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMagicLink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lpp_proge.GetMagicLink();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mail = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMagicLink message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lpp_proge.GetMagicLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lpp_proge.GetMagicLink} GetMagicLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMagicLink.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMagicLink message.
         * @function verify
         * @memberof lpp_proge.GetMagicLink
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMagicLink.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mail != null && message.hasOwnProperty("mail"))
                if (!$util.isString(message.mail))
                    return "mail: string expected";
            return null;
        };

        /**
         * Creates a GetMagicLink message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lpp_proge.GetMagicLink
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lpp_proge.GetMagicLink} GetMagicLink
         */
        GetMagicLink.fromObject = function fromObject(object) {
            if (object instanceof $root.lpp_proge.GetMagicLink)
                return object;
            let message = new $root.lpp_proge.GetMagicLink();
            if (object.mail != null)
                message.mail = String(object.mail);
            return message;
        };

        /**
         * Creates a plain object from a GetMagicLink message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lpp_proge.GetMagicLink
         * @static
         * @param {lpp_proge.GetMagicLink} message GetMagicLink
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMagicLink.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.mail = "";
            if (message.mail != null && message.hasOwnProperty("mail"))
                object.mail = message.mail;
            return object;
        };

        /**
         * Converts this GetMagicLink to JSON.
         * @function toJSON
         * @memberof lpp_proge.GetMagicLink
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMagicLink.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetMagicLink
         * @function getTypeUrl
         * @memberof lpp_proge.GetMagicLink
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMagicLink.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/lpp_proge.GetMagicLink";
        };

        return GetMagicLink;
    })();

    lpp_proge.LoginMagicLink = (function() {

        /**
         * Properties of a LoginMagicLink.
         * @memberof lpp_proge
         * @interface ILoginMagicLink
         * @property {string|null} [token] LoginMagicLink token
         */

        /**
         * Constructs a new LoginMagicLink.
         * @memberof lpp_proge
         * @classdesc Represents a LoginMagicLink.
         * @implements ILoginMagicLink
         * @constructor
         * @param {lpp_proge.ILoginMagicLink=} [properties] Properties to set
         */
        function LoginMagicLink(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginMagicLink token.
         * @member {string} token
         * @memberof lpp_proge.LoginMagicLink
         * @instance
         */
        LoginMagicLink.prototype.token = "";

        /**
         * Creates a new LoginMagicLink instance using the specified properties.
         * @function create
         * @memberof lpp_proge.LoginMagicLink
         * @static
         * @param {lpp_proge.ILoginMagicLink=} [properties] Properties to set
         * @returns {lpp_proge.LoginMagicLink} LoginMagicLink instance
         */
        LoginMagicLink.create = function create(properties) {
            return new LoginMagicLink(properties);
        };

        /**
         * Encodes the specified LoginMagicLink message. Does not implicitly {@link lpp_proge.LoginMagicLink.verify|verify} messages.
         * @function encode
         * @memberof lpp_proge.LoginMagicLink
         * @static
         * @param {lpp_proge.ILoginMagicLink} message LoginMagicLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginMagicLink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified LoginMagicLink message, length delimited. Does not implicitly {@link lpp_proge.LoginMagicLink.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lpp_proge.LoginMagicLink
         * @static
         * @param {lpp_proge.ILoginMagicLink} message LoginMagicLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginMagicLink.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginMagicLink message from the specified reader or buffer.
         * @function decode
         * @memberof lpp_proge.LoginMagicLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lpp_proge.LoginMagicLink} LoginMagicLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginMagicLink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lpp_proge.LoginMagicLink();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.token = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginMagicLink message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lpp_proge.LoginMagicLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lpp_proge.LoginMagicLink} LoginMagicLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginMagicLink.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginMagicLink message.
         * @function verify
         * @memberof lpp_proge.LoginMagicLink
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginMagicLink.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates a LoginMagicLink message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lpp_proge.LoginMagicLink
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lpp_proge.LoginMagicLink} LoginMagicLink
         */
        LoginMagicLink.fromObject = function fromObject(object) {
            if (object instanceof $root.lpp_proge.LoginMagicLink)
                return object;
            let message = new $root.lpp_proge.LoginMagicLink();
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from a LoginMagicLink message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lpp_proge.LoginMagicLink
         * @static
         * @param {lpp_proge.LoginMagicLink} message LoginMagicLink
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginMagicLink.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.token = "";
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this LoginMagicLink to JSON.
         * @function toJSON
         * @memberof lpp_proge.LoginMagicLink
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginMagicLink.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginMagicLink
         * @function getTypeUrl
         * @memberof lpp_proge.LoginMagicLink
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginMagicLink.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/lpp_proge.LoginMagicLink";
        };

        return LoginMagicLink;
    })();

    lpp_proge.LoginMagicLinkStatus = (function() {

        /**
         * Properties of a LoginMagicLinkStatus.
         * @memberof lpp_proge
         * @interface ILoginMagicLinkStatus
         * @property {string|null} [status] LoginMagicLinkStatus status
         * @property {boolean|null} [error] LoginMagicLinkStatus error
         */

        /**
         * Constructs a new LoginMagicLinkStatus.
         * @memberof lpp_proge
         * @classdesc Represents a LoginMagicLinkStatus.
         * @implements ILoginMagicLinkStatus
         * @constructor
         * @param {lpp_proge.ILoginMagicLinkStatus=} [properties] Properties to set
         */
        function LoginMagicLinkStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginMagicLinkStatus status.
         * @member {string} status
         * @memberof lpp_proge.LoginMagicLinkStatus
         * @instance
         */
        LoginMagicLinkStatus.prototype.status = "";

        /**
         * LoginMagicLinkStatus error.
         * @member {boolean} error
         * @memberof lpp_proge.LoginMagicLinkStatus
         * @instance
         */
        LoginMagicLinkStatus.prototype.error = false;

        /**
         * Creates a new LoginMagicLinkStatus instance using the specified properties.
         * @function create
         * @memberof lpp_proge.LoginMagicLinkStatus
         * @static
         * @param {lpp_proge.ILoginMagicLinkStatus=} [properties] Properties to set
         * @returns {lpp_proge.LoginMagicLinkStatus} LoginMagicLinkStatus instance
         */
        LoginMagicLinkStatus.create = function create(properties) {
            return new LoginMagicLinkStatus(properties);
        };

        /**
         * Encodes the specified LoginMagicLinkStatus message. Does not implicitly {@link lpp_proge.LoginMagicLinkStatus.verify|verify} messages.
         * @function encode
         * @memberof lpp_proge.LoginMagicLinkStatus
         * @static
         * @param {lpp_proge.ILoginMagicLinkStatus} message LoginMagicLinkStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginMagicLinkStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.status);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.error);
            return writer;
        };

        /**
         * Encodes the specified LoginMagicLinkStatus message, length delimited. Does not implicitly {@link lpp_proge.LoginMagicLinkStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lpp_proge.LoginMagicLinkStatus
         * @static
         * @param {lpp_proge.ILoginMagicLinkStatus} message LoginMagicLinkStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginMagicLinkStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginMagicLinkStatus message from the specified reader or buffer.
         * @function decode
         * @memberof lpp_proge.LoginMagicLinkStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lpp_proge.LoginMagicLinkStatus} LoginMagicLinkStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginMagicLinkStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lpp_proge.LoginMagicLinkStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.string();
                        break;
                    }
                case 2: {
                        message.error = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginMagicLinkStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lpp_proge.LoginMagicLinkStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lpp_proge.LoginMagicLinkStatus} LoginMagicLinkStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginMagicLinkStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginMagicLinkStatus message.
         * @function verify
         * @memberof lpp_proge.LoginMagicLinkStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginMagicLinkStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (typeof message.error !== "boolean")
                    return "error: boolean expected";
            return null;
        };

        /**
         * Creates a LoginMagicLinkStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lpp_proge.LoginMagicLinkStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lpp_proge.LoginMagicLinkStatus} LoginMagicLinkStatus
         */
        LoginMagicLinkStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.lpp_proge.LoginMagicLinkStatus)
                return object;
            let message = new $root.lpp_proge.LoginMagicLinkStatus();
            if (object.status != null)
                message.status = String(object.status);
            if (object.error != null)
                message.error = Boolean(object.error);
            return message;
        };

        /**
         * Creates a plain object from a LoginMagicLinkStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lpp_proge.LoginMagicLinkStatus
         * @static
         * @param {lpp_proge.LoginMagicLinkStatus} message LoginMagicLinkStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginMagicLinkStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = "";
                object.error = false;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            return object;
        };

        /**
         * Converts this LoginMagicLinkStatus to JSON.
         * @function toJSON
         * @memberof lpp_proge.LoginMagicLinkStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginMagicLinkStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginMagicLinkStatus
         * @function getTypeUrl
         * @memberof lpp_proge.LoginMagicLinkStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginMagicLinkStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/lpp_proge.LoginMagicLinkStatus";
        };

        return LoginMagicLinkStatus;
    })();

    lpp_proge.UserProfileData = (function() {

        /**
         * Properties of a UserProfileData.
         * @memberof lpp_proge
         * @interface IUserProfileData
         * @property {string|null} [name] UserProfileData name
         * @property {string|null} [phone] UserProfileData phone
         */

        /**
         * Constructs a new UserProfileData.
         * @memberof lpp_proge
         * @classdesc Represents a UserProfileData.
         * @implements IUserProfileData
         * @constructor
         * @param {lpp_proge.IUserProfileData=} [properties] Properties to set
         */
        function UserProfileData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserProfileData name.
         * @member {string} name
         * @memberof lpp_proge.UserProfileData
         * @instance
         */
        UserProfileData.prototype.name = "";

        /**
         * UserProfileData phone.
         * @member {string} phone
         * @memberof lpp_proge.UserProfileData
         * @instance
         */
        UserProfileData.prototype.phone = "";

        /**
         * Creates a new UserProfileData instance using the specified properties.
         * @function create
         * @memberof lpp_proge.UserProfileData
         * @static
         * @param {lpp_proge.IUserProfileData=} [properties] Properties to set
         * @returns {lpp_proge.UserProfileData} UserProfileData instance
         */
        UserProfileData.create = function create(properties) {
            return new UserProfileData(properties);
        };

        /**
         * Encodes the specified UserProfileData message. Does not implicitly {@link lpp_proge.UserProfileData.verify|verify} messages.
         * @function encode
         * @memberof lpp_proge.UserProfileData
         * @static
         * @param {lpp_proge.IUserProfileData} message UserProfileData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserProfileData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.phone);
            return writer;
        };

        /**
         * Encodes the specified UserProfileData message, length delimited. Does not implicitly {@link lpp_proge.UserProfileData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lpp_proge.UserProfileData
         * @static
         * @param {lpp_proge.IUserProfileData} message UserProfileData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserProfileData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserProfileData message from the specified reader or buffer.
         * @function decode
         * @memberof lpp_proge.UserProfileData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lpp_proge.UserProfileData} UserProfileData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserProfileData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lpp_proge.UserProfileData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.phone = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserProfileData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lpp_proge.UserProfileData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lpp_proge.UserProfileData} UserProfileData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserProfileData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserProfileData message.
         * @function verify
         * @memberof lpp_proge.UserProfileData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserProfileData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.phone != null && message.hasOwnProperty("phone"))
                if (!$util.isString(message.phone))
                    return "phone: string expected";
            return null;
        };

        /**
         * Creates a UserProfileData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lpp_proge.UserProfileData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lpp_proge.UserProfileData} UserProfileData
         */
        UserProfileData.fromObject = function fromObject(object) {
            if (object instanceof $root.lpp_proge.UserProfileData)
                return object;
            let message = new $root.lpp_proge.UserProfileData();
            if (object.name != null)
                message.name = String(object.name);
            if (object.phone != null)
                message.phone = String(object.phone);
            return message;
        };

        /**
         * Creates a plain object from a UserProfileData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lpp_proge.UserProfileData
         * @static
         * @param {lpp_proge.UserProfileData} message UserProfileData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserProfileData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.phone = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            return object;
        };

        /**
         * Converts this UserProfileData to JSON.
         * @function toJSON
         * @memberof lpp_proge.UserProfileData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserProfileData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserProfileData
         * @function getTypeUrl
         * @memberof lpp_proge.UserProfileData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserProfileData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/lpp_proge.UserProfileData";
        };

        return UserProfileData;
    })();

    return lpp_proge;
})();

export const sfiles = $root.sfiles = (() => {

    /**
     * Namespace sfiles.
     * @exports sfiles
     * @namespace
     */
    const sfiles = {};

    sfiles.UploadStartSlot = (function() {

        /**
         * Properties of an UploadStartSlot.
         * @memberof sfiles
         * @interface IUploadStartSlot
         * @property {string|null} [key] UploadStartSlot key
         * @property {string|null} [localKey] UploadStartSlot localKey
         */

        /**
         * Constructs a new UploadStartSlot.
         * @memberof sfiles
         * @classdesc Represents an UploadStartSlot.
         * @implements IUploadStartSlot
         * @constructor
         * @param {sfiles.IUploadStartSlot=} [properties] Properties to set
         */
        function UploadStartSlot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadStartSlot key.
         * @member {string} key
         * @memberof sfiles.UploadStartSlot
         * @instance
         */
        UploadStartSlot.prototype.key = "";

        /**
         * UploadStartSlot localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadStartSlot
         * @instance
         */
        UploadStartSlot.prototype.localKey = "";

        /**
         * Creates a new UploadStartSlot instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot=} [properties] Properties to set
         * @returns {sfiles.UploadStartSlot} UploadStartSlot instance
         */
        UploadStartSlot.create = function create(properties) {
            return new UploadStartSlot(properties);
        };

        /**
         * Encodes the specified UploadStartSlot message. Does not implicitly {@link sfiles.UploadStartSlot.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot} message UploadStartSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStartSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.localKey);
            return writer;
        };

        /**
         * Encodes the specified UploadStartSlot message, length delimited. Does not implicitly {@link sfiles.UploadStartSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot} message UploadStartSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStartSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadStartSlot message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStartSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadStartSlot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.localKey = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadStartSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStartSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadStartSlot message.
         * @function verify
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadStartSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            return null;
        };

        /**
         * Creates an UploadStartSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         */
        UploadStartSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadStartSlot)
                return object;
            let message = new $root.sfiles.UploadStartSlot();
            if (object.key != null)
                message.key = String(object.key);
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            return message;
        };

        /**
         * Creates a plain object from an UploadStartSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.UploadStartSlot} message UploadStartSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadStartSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.localKey = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            return object;
        };

        /**
         * Converts this UploadStartSlot to JSON.
         * @function toJSON
         * @memberof sfiles.UploadStartSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadStartSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadStartSlot
         * @function getTypeUrl
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadStartSlot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadStartSlot";
        };

        return UploadStartSlot;
    })();

    sfiles.UploadProgress = (function() {

        /**
         * Properties of an UploadProgress.
         * @memberof sfiles
         * @interface IUploadProgress
         * @property {number|Long|null} [nBytes] UploadProgress nBytes
         * @property {string|null} [key] UploadProgress key
         * @property {string|null} [localKey] UploadProgress localKey
         */

        /**
         * Constructs a new UploadProgress.
         * @memberof sfiles
         * @classdesc Represents an UploadProgress.
         * @implements IUploadProgress
         * @constructor
         * @param {sfiles.IUploadProgress=} [properties] Properties to set
         */
        function UploadProgress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadProgress nBytes.
         * @member {number|Long} nBytes
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.nBytes = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UploadProgress key.
         * @member {string} key
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.key = "";

        /**
         * UploadProgress localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.localKey = "";

        /**
         * Creates a new UploadProgress instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress=} [properties] Properties to set
         * @returns {sfiles.UploadProgress} UploadProgress instance
         */
        UploadProgress.create = function create(properties) {
            return new UploadProgress(properties);
        };

        /**
         * Encodes the specified UploadProgress message. Does not implicitly {@link sfiles.UploadProgress.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress} message UploadProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nBytes != null && Object.hasOwnProperty.call(message, "nBytes"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.nBytes);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.localKey);
            return writer;
        };

        /**
         * Encodes the specified UploadProgress message, length delimited. Does not implicitly {@link sfiles.UploadProgress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress} message UploadProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadProgress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadProgress message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadProgress} UploadProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadProgress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nBytes = reader.int64();
                        break;
                    }
                case 2: {
                        message.key = reader.string();
                        break;
                    }
                case 3: {
                        message.localKey = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadProgress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadProgress} UploadProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadProgress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadProgress message.
         * @function verify
         * @memberof sfiles.UploadProgress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadProgress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nBytes != null && message.hasOwnProperty("nBytes"))
                if (!$util.isInteger(message.nBytes) && !(message.nBytes && $util.isInteger(message.nBytes.low) && $util.isInteger(message.nBytes.high)))
                    return "nBytes: integer|Long expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            return null;
        };

        /**
         * Creates an UploadProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadProgress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadProgress} UploadProgress
         */
        UploadProgress.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadProgress)
                return object;
            let message = new $root.sfiles.UploadProgress();
            if (object.nBytes != null)
                if ($util.Long)
                    (message.nBytes = $util.Long.fromValue(object.nBytes)).unsigned = false;
                else if (typeof object.nBytes === "string")
                    message.nBytes = parseInt(object.nBytes, 10);
                else if (typeof object.nBytes === "number")
                    message.nBytes = object.nBytes;
                else if (typeof object.nBytes === "object")
                    message.nBytes = new $util.LongBits(object.nBytes.low >>> 0, object.nBytes.high >>> 0).toNumber();
            if (object.key != null)
                message.key = String(object.key);
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            return message;
        };

        /**
         * Creates a plain object from an UploadProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.UploadProgress} message UploadProgress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadProgress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.nBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nBytes = options.longs === String ? "0" : 0;
                object.key = "";
                object.localKey = "";
            }
            if (message.nBytes != null && message.hasOwnProperty("nBytes"))
                if (typeof message.nBytes === "number")
                    object.nBytes = options.longs === String ? String(message.nBytes) : message.nBytes;
                else
                    object.nBytes = options.longs === String ? $util.Long.prototype.toString.call(message.nBytes) : options.longs === Number ? new $util.LongBits(message.nBytes.low >>> 0, message.nBytes.high >>> 0).toNumber() : message.nBytes;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            return object;
        };

        /**
         * Converts this UploadProgress to JSON.
         * @function toJSON
         * @memberof sfiles.UploadProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadProgress
         * @function getTypeUrl
         * @memberof sfiles.UploadProgress
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadProgress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadProgress";
        };

        return UploadProgress;
    })();

    sfiles.UploadDone = (function() {

        /**
         * Properties of an UploadDone.
         * @memberof sfiles
         * @interface IUploadDone
         * @property {string|null} [key] UploadDone key
         * @property {sfiles.IUploadedFile|null} [file] UploadDone file
         */

        /**
         * Constructs a new UploadDone.
         * @memberof sfiles
         * @classdesc Represents an UploadDone.
         * @implements IUploadDone
         * @constructor
         * @param {sfiles.IUploadDone=} [properties] Properties to set
         */
        function UploadDone(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadDone key.
         * @member {string} key
         * @memberof sfiles.UploadDone
         * @instance
         */
        UploadDone.prototype.key = "";

        /**
         * UploadDone file.
         * @member {sfiles.IUploadedFile|null|undefined} file
         * @memberof sfiles.UploadDone
         * @instance
         */
        UploadDone.prototype.file = null;

        /**
         * Creates a new UploadDone instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone=} [properties] Properties to set
         * @returns {sfiles.UploadDone} UploadDone instance
         */
        UploadDone.create = function create(properties) {
            return new UploadDone(properties);
        };

        /**
         * Encodes the specified UploadDone message. Does not implicitly {@link sfiles.UploadDone.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone} message UploadDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadDone.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.sfiles.UploadedFile.encode(message.file, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadDone message, length delimited. Does not implicitly {@link sfiles.UploadDone.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone} message UploadDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadDone.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadDone message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadDone} UploadDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadDone.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadDone();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.file = $root.sfiles.UploadedFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadDone message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadDone} UploadDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadDone.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadDone message.
         * @function verify
         * @memberof sfiles.UploadDone
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadDone.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.sfiles.UploadedFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            return null;
        };

        /**
         * Creates an UploadDone message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadDone
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadDone} UploadDone
         */
        UploadDone.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadDone)
                return object;
            let message = new $root.sfiles.UploadDone();
            if (object.key != null)
                message.key = String(object.key);
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".sfiles.UploadDone.file: object expected");
                message.file = $root.sfiles.UploadedFile.fromObject(object.file);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadDone message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.UploadDone} message UploadDone
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadDone.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.file = null;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.sfiles.UploadedFile.toObject(message.file, options);
            return object;
        };

        /**
         * Converts this UploadDone to JSON.
         * @function toJSON
         * @memberof sfiles.UploadDone
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadDone.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadDone
         * @function getTypeUrl
         * @memberof sfiles.UploadDone
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadDone.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadDone";
        };

        return UploadDone;
    })();

    sfiles.UploadedFile = (function() {

        /**
         * Properties of an UploadedFile.
         * @memberof sfiles
         * @interface IUploadedFile
         * @property {string|null} [localKey] UploadedFile localKey
         * @property {string|null} [url] UploadedFile url
         * @property {string|null} [id] UploadedFile id
         * @property {string|null} [mime] UploadedFile mime
         * @property {string|null} [thumbUrl] UploadedFile thumbUrl
         * @property {string|null} [name] UploadedFile name
         */

        /**
         * Constructs a new UploadedFile.
         * @memberof sfiles
         * @classdesc Represents an UploadedFile.
         * @implements IUploadedFile
         * @constructor
         * @param {sfiles.IUploadedFile=} [properties] Properties to set
         */
        function UploadedFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadedFile localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.localKey = "";

        /**
         * UploadedFile url.
         * @member {string} url
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.url = "";

        /**
         * UploadedFile id.
         * @member {string} id
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.id = "";

        /**
         * UploadedFile mime.
         * @member {string} mime
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.mime = "";

        /**
         * UploadedFile thumbUrl.
         * @member {string} thumbUrl
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.thumbUrl = "";

        /**
         * UploadedFile name.
         * @member {string} name
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.name = "";

        /**
         * Creates a new UploadedFile instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile=} [properties] Properties to set
         * @returns {sfiles.UploadedFile} UploadedFile instance
         */
        UploadedFile.create = function create(properties) {
            return new UploadedFile(properties);
        };

        /**
         * Encodes the specified UploadedFile message. Does not implicitly {@link sfiles.UploadedFile.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile} message UploadedFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadedFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.localKey);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            if (message.thumbUrl != null && Object.hasOwnProperty.call(message, "thumbUrl"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.thumbUrl);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified UploadedFile message, length delimited. Does not implicitly {@link sfiles.UploadedFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile} message UploadedFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadedFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadedFile message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadedFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadedFile} UploadedFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadedFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadedFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localKey = reader.string();
                        break;
                    }
                case 2: {
                        message.url = reader.string();
                        break;
                    }
                case 3: {
                        message.id = reader.string();
                        break;
                    }
                case 4: {
                        message.mime = reader.string();
                        break;
                    }
                case 5: {
                        message.thumbUrl = reader.string();
                        break;
                    }
                case 6: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadedFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadedFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadedFile} UploadedFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadedFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadedFile message.
         * @function verify
         * @memberof sfiles.UploadedFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadedFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            if (message.thumbUrl != null && message.hasOwnProperty("thumbUrl"))
                if (!$util.isString(message.thumbUrl))
                    return "thumbUrl: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates an UploadedFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadedFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadedFile} UploadedFile
         */
        UploadedFile.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadedFile)
                return object;
            let message = new $root.sfiles.UploadedFile();
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            if (object.url != null)
                message.url = String(object.url);
            if (object.id != null)
                message.id = String(object.id);
            if (object.mime != null)
                message.mime = String(object.mime);
            if (object.thumbUrl != null)
                message.thumbUrl = String(object.thumbUrl);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from an UploadedFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.UploadedFile} message UploadedFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadedFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.localKey = "";
                object.url = "";
                object.id = "";
                object.mime = "";
                object.thumbUrl = "";
                object.name = "";
            }
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            if (message.thumbUrl != null && message.hasOwnProperty("thumbUrl"))
                object.thumbUrl = message.thumbUrl;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this UploadedFile to JSON.
         * @function toJSON
         * @memberof sfiles.UploadedFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadedFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadedFile
         * @function getTypeUrl
         * @memberof sfiles.UploadedFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadedFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadedFile";
        };

        return UploadedFile;
    })();

    sfiles.UploadStart = (function() {

        /**
         * Properties of an UploadStart.
         * @memberof sfiles
         * @interface IUploadStart
         * @property {string|null} [localKey] UploadStart localKey
         * @property {string|null} [extension] UploadStart extension
         * @property {string|null} [name] UploadStart name
         * @property {string|null} [mime] UploadStart mime
         */

        /**
         * Constructs a new UploadStart.
         * @memberof sfiles
         * @classdesc Represents an UploadStart.
         * @implements IUploadStart
         * @constructor
         * @param {sfiles.IUploadStart=} [properties] Properties to set
         */
        function UploadStart(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadStart localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.localKey = "";

        /**
         * UploadStart extension.
         * @member {string} extension
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.extension = "";

        /**
         * UploadStart name.
         * @member {string} name
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.name = "";

        /**
         * UploadStart mime.
         * @member {string} mime
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.mime = "";

        /**
         * Creates a new UploadStart instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart=} [properties] Properties to set
         * @returns {sfiles.UploadStart} UploadStart instance
         */
        UploadStart.create = function create(properties) {
            return new UploadStart(properties);
        };

        /**
         * Encodes the specified UploadStart message. Does not implicitly {@link sfiles.UploadStart.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart} message UploadStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.localKey);
            if (message.extension != null && Object.hasOwnProperty.call(message, "extension"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.extension);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            return writer;
        };

        /**
         * Encodes the specified UploadStart message, length delimited. Does not implicitly {@link sfiles.UploadStart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart} message UploadStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadStart message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadStart} UploadStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadStart();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localKey = reader.string();
                        break;
                    }
                case 2: {
                        message.extension = reader.string();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.mime = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadStart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadStart} UploadStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadStart message.
         * @function verify
         * @memberof sfiles.UploadStart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadStart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            if (message.extension != null && message.hasOwnProperty("extension"))
                if (!$util.isString(message.extension))
                    return "extension: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            return null;
        };

        /**
         * Creates an UploadStart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadStart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadStart} UploadStart
         */
        UploadStart.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadStart)
                return object;
            let message = new $root.sfiles.UploadStart();
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            if (object.extension != null)
                message.extension = String(object.extension);
            if (object.name != null)
                message.name = String(object.name);
            if (object.mime != null)
                message.mime = String(object.mime);
            return message;
        };

        /**
         * Creates a plain object from an UploadStart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.UploadStart} message UploadStart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadStart.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.localKey = "";
                object.extension = "";
                object.name = "";
                object.mime = "";
            }
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            if (message.extension != null && message.hasOwnProperty("extension"))
                object.extension = message.extension;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            return object;
        };

        /**
         * Converts this UploadStart to JSON.
         * @function toJSON
         * @memberof sfiles.UploadStart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadStart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadStart
         * @function getTypeUrl
         * @memberof sfiles.UploadStart
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadStart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadStart";
        };

        return UploadStart;
    })();

    sfiles.DeleteFile = (function() {

        /**
         * Properties of a DeleteFile.
         * @memberof sfiles
         * @interface IDeleteFile
         * @property {sfiles.IUploadedFile|null} [file] DeleteFile file
         */

        /**
         * Constructs a new DeleteFile.
         * @memberof sfiles
         * @classdesc Represents a DeleteFile.
         * @implements IDeleteFile
         * @constructor
         * @param {sfiles.IDeleteFile=} [properties] Properties to set
         */
        function DeleteFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteFile file.
         * @member {sfiles.IUploadedFile|null|undefined} file
         * @memberof sfiles.DeleteFile
         * @instance
         */
        DeleteFile.prototype.file = null;

        /**
         * Creates a new DeleteFile instance using the specified properties.
         * @function create
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile=} [properties] Properties to set
         * @returns {sfiles.DeleteFile} DeleteFile instance
         */
        DeleteFile.create = function create(properties) {
            return new DeleteFile(properties);
        };

        /**
         * Encodes the specified DeleteFile message. Does not implicitly {@link sfiles.DeleteFile.verify|verify} messages.
         * @function encode
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile} message DeleteFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.sfiles.UploadedFile.encode(message.file, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DeleteFile message, length delimited. Does not implicitly {@link sfiles.DeleteFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile} message DeleteFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteFile message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.DeleteFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.DeleteFile} DeleteFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.DeleteFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.file = $root.sfiles.UploadedFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.DeleteFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.DeleteFile} DeleteFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteFile message.
         * @function verify
         * @memberof sfiles.DeleteFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.sfiles.UploadedFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            return null;
        };

        /**
         * Creates a DeleteFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.DeleteFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.DeleteFile} DeleteFile
         */
        DeleteFile.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.DeleteFile)
                return object;
            let message = new $root.sfiles.DeleteFile();
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".sfiles.DeleteFile.file: object expected");
                message.file = $root.sfiles.UploadedFile.fromObject(object.file);
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.DeleteFile} message DeleteFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.file = null;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.sfiles.UploadedFile.toObject(message.file, options);
            return object;
        };

        /**
         * Converts this DeleteFile to JSON.
         * @function toJSON
         * @memberof sfiles.DeleteFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteFile
         * @function getTypeUrl
         * @memberof sfiles.DeleteFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.DeleteFile";
        };

        return DeleteFile;
    })();

    sfiles.UploadEnd = (function() {

        /**
         * Properties of an UploadEnd.
         * @memberof sfiles
         * @interface IUploadEnd
         */

        /**
         * Constructs a new UploadEnd.
         * @memberof sfiles
         * @classdesc Represents an UploadEnd.
         * @implements IUploadEnd
         * @constructor
         * @param {sfiles.IUploadEnd=} [properties] Properties to set
         */
        function UploadEnd(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UploadEnd instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd=} [properties] Properties to set
         * @returns {sfiles.UploadEnd} UploadEnd instance
         */
        UploadEnd.create = function create(properties) {
            return new UploadEnd(properties);
        };

        /**
         * Encodes the specified UploadEnd message. Does not implicitly {@link sfiles.UploadEnd.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd} message UploadEnd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadEnd.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UploadEnd message, length delimited. Does not implicitly {@link sfiles.UploadEnd.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd} message UploadEnd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadEnd.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadEnd message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadEnd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadEnd} UploadEnd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadEnd.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadEnd();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadEnd message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadEnd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadEnd} UploadEnd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadEnd.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadEnd message.
         * @function verify
         * @memberof sfiles.UploadEnd
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadEnd.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UploadEnd message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadEnd
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadEnd} UploadEnd
         */
        UploadEnd.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadEnd)
                return object;
            return new $root.sfiles.UploadEnd();
        };

        /**
         * Creates a plain object from an UploadEnd message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.UploadEnd} message UploadEnd
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadEnd.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UploadEnd to JSON.
         * @function toJSON
         * @memberof sfiles.UploadEnd
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadEnd.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadEnd
         * @function getTypeUrl
         * @memberof sfiles.UploadEnd
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadEnd.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadEnd";
        };

        return UploadEnd;
    })();

    return sfiles;
})();

export const socket_api = $root.socket_api = (() => {

    /**
     * Namespace socket_api.
     * @exports socket_api
     * @namespace
     */
    const socket_api = {};

    socket_api.Ack = (function() {

        /**
         * Properties of an Ack.
         * @memberof socket_api
         * @interface IAck
         * @property {string|null} [uuid] Ack uuid
         * @property {string|null} [errorMessage] Ack errorMessage
         * @property {string|null} [asyncProgressKey] Ack asyncProgressKey
         * @property {number|null} [errorCode] Ack errorCode
         */

        /**
         * Constructs a new Ack.
         * @memberof socket_api
         * @classdesc Represents an Ack.
         * @implements IAck
         * @constructor
         * @param {socket_api.IAck=} [properties] Properties to set
         */
        function Ack(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Ack uuid.
         * @member {string} uuid
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.uuid = "";

        /**
         * Ack errorMessage.
         * @member {string} errorMessage
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.errorMessage = "";

        /**
         * Ack asyncProgressKey.
         * @member {string} asyncProgressKey
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.asyncProgressKey = "";

        /**
         * Ack errorCode.
         * @member {number} errorCode
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.errorCode = 0;

        /**
         * Creates a new Ack instance using the specified properties.
         * @function create
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck=} [properties] Properties to set
         * @returns {socket_api.Ack} Ack instance
         */
        Ack.create = function create(properties) {
            return new Ack(properties);
        };

        /**
         * Encodes the specified Ack message. Does not implicitly {@link socket_api.Ack.verify|verify} messages.
         * @function encode
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck} message Ack message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ack.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorMessage);
            if (message.asyncProgressKey != null && Object.hasOwnProperty.call(message, "asyncProgressKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.asyncProgressKey);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified Ack message, length delimited. Does not implicitly {@link socket_api.Ack.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck} message Ack message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ack.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Ack message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.Ack
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.Ack} Ack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ack.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.Ack();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.uuid = reader.string();
                        break;
                    }
                case 2: {
                        message.errorMessage = reader.string();
                        break;
                    }
                case 3: {
                        message.asyncProgressKey = reader.string();
                        break;
                    }
                case 4: {
                        message.errorCode = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Ack message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.Ack
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.Ack} Ack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ack.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Ack message.
         * @function verify
         * @memberof socket_api.Ack
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Ack.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!$util.isString(message.uuid))
                    return "uuid: string expected";
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            if (message.asyncProgressKey != null && message.hasOwnProperty("asyncProgressKey"))
                if (!$util.isString(message.asyncProgressKey))
                    return "asyncProgressKey: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isInteger(message.errorCode))
                    return "errorCode: integer expected";
            return null;
        };

        /**
         * Creates an Ack message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.Ack
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.Ack} Ack
         */
        Ack.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.Ack)
                return object;
            let message = new $root.socket_api.Ack();
            if (object.uuid != null)
                message.uuid = String(object.uuid);
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            if (object.asyncProgressKey != null)
                message.asyncProgressKey = String(object.asyncProgressKey);
            if (object.errorCode != null)
                message.errorCode = object.errorCode | 0;
            return message;
        };

        /**
         * Creates a plain object from an Ack message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.Ack} message Ack
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Ack.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.uuid = "";
                object.errorMessage = "";
                object.asyncProgressKey = "";
                object.errorCode = 0;
            }
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                object.uuid = message.uuid;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            if (message.asyncProgressKey != null && message.hasOwnProperty("asyncProgressKey"))
                object.asyncProgressKey = message.asyncProgressKey;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this Ack to JSON.
         * @function toJSON
         * @memberof socket_api.Ack
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Ack.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Ack
         * @function getTypeUrl
         * @memberof socket_api.Ack
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Ack.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/socket_api.Ack";
        };

        return Ack;
    })();

    socket_api.AsyncProgress = (function() {

        /**
         * Properties of an AsyncProgress.
         * @memberof socket_api
         * @interface IAsyncProgress
         * @property {string|null} [key] AsyncProgress key
         * @property {number|null} [progress] AsyncProgress progress
         * @property {string|null} [info] AsyncProgress info
         * @property {boolean|null} [done] AsyncProgress done
         * @property {string|null} [errorMessage] AsyncProgress errorMessage
         */

        /**
         * Constructs a new AsyncProgress.
         * @memberof socket_api
         * @classdesc Represents an AsyncProgress.
         * @implements IAsyncProgress
         * @constructor
         * @param {socket_api.IAsyncProgress=} [properties] Properties to set
         */
        function AsyncProgress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsyncProgress key.
         * @member {string} key
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.key = "";

        /**
         * AsyncProgress progress.
         * @member {number} progress
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.progress = 0;

        /**
         * AsyncProgress info.
         * @member {string} info
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.info = "";

        /**
         * AsyncProgress done.
         * @member {boolean} done
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.done = false;

        /**
         * AsyncProgress errorMessage.
         * @member {string} errorMessage
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.errorMessage = "";

        /**
         * Creates a new AsyncProgress instance using the specified properties.
         * @function create
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress=} [properties] Properties to set
         * @returns {socket_api.AsyncProgress} AsyncProgress instance
         */
        AsyncProgress.create = function create(properties) {
            return new AsyncProgress(properties);
        };

        /**
         * Encodes the specified AsyncProgress message. Does not implicitly {@link socket_api.AsyncProgress.verify|verify} messages.
         * @function encode
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress} message AsyncProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsyncProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.progress != null && Object.hasOwnProperty.call(message, "progress"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.progress);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.info);
            if (message.done != null && Object.hasOwnProperty.call(message, "done"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.done);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.errorMessage);
            return writer;
        };

        /**
         * Encodes the specified AsyncProgress message, length delimited. Does not implicitly {@link socket_api.AsyncProgress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress} message AsyncProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsyncProgress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsyncProgress message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.AsyncProgress} AsyncProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsyncProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.AsyncProgress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.progress = reader.double();
                        break;
                    }
                case 3: {
                        message.info = reader.string();
                        break;
                    }
                case 4: {
                        message.done = reader.bool();
                        break;
                    }
                case 5: {
                        message.errorMessage = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsyncProgress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.AsyncProgress} AsyncProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsyncProgress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsyncProgress message.
         * @function verify
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsyncProgress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.progress != null && message.hasOwnProperty("progress"))
                if (typeof message.progress !== "number")
                    return "progress: number expected";
            if (message.info != null && message.hasOwnProperty("info"))
                if (!$util.isString(message.info))
                    return "info: string expected";
            if (message.done != null && message.hasOwnProperty("done"))
                if (typeof message.done !== "boolean")
                    return "done: boolean expected";
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            return null;
        };

        /**
         * Creates an AsyncProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.AsyncProgress} AsyncProgress
         */
        AsyncProgress.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.AsyncProgress)
                return object;
            let message = new $root.socket_api.AsyncProgress();
            if (object.key != null)
                message.key = String(object.key);
            if (object.progress != null)
                message.progress = Number(object.progress);
            if (object.info != null)
                message.info = String(object.info);
            if (object.done != null)
                message.done = Boolean(object.done);
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            return message;
        };

        /**
         * Creates a plain object from an AsyncProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.AsyncProgress} message AsyncProgress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsyncProgress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.progress = 0;
                object.info = "";
                object.done = false;
                object.errorMessage = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.progress != null && message.hasOwnProperty("progress"))
                object.progress = options.json && !isFinite(message.progress) ? String(message.progress) : message.progress;
            if (message.info != null && message.hasOwnProperty("info"))
                object.info = message.info;
            if (message.done != null && message.hasOwnProperty("done"))
                object.done = message.done;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            return object;
        };

        /**
         * Converts this AsyncProgress to JSON.
         * @function toJSON
         * @memberof socket_api.AsyncProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsyncProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsyncProgress
         * @function getTypeUrl
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsyncProgress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/socket_api.AsyncProgress";
        };

        return AsyncProgress;
    })();

    socket_api.UpgradeApiVersion = (function() {

        /**
         * Properties of an UpgradeApiVersion.
         * @memberof socket_api
         * @interface IUpgradeApiVersion
         * @property {number|null} [latest] UpgradeApiVersion latest
         */

        /**
         * Constructs a new UpgradeApiVersion.
         * @memberof socket_api
         * @classdesc Represents an UpgradeApiVersion.
         * @implements IUpgradeApiVersion
         * @constructor
         * @param {socket_api.IUpgradeApiVersion=} [properties] Properties to set
         */
        function UpgradeApiVersion(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpgradeApiVersion latest.
         * @member {number} latest
         * @memberof socket_api.UpgradeApiVersion
         * @instance
         */
        UpgradeApiVersion.prototype.latest = 0;

        /**
         * Creates a new UpgradeApiVersion instance using the specified properties.
         * @function create
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion=} [properties] Properties to set
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion instance
         */
        UpgradeApiVersion.create = function create(properties) {
            return new UpgradeApiVersion(properties);
        };

        /**
         * Encodes the specified UpgradeApiVersion message. Does not implicitly {@link socket_api.UpgradeApiVersion.verify|verify} messages.
         * @function encode
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion} message UpgradeApiVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpgradeApiVersion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.latest != null && Object.hasOwnProperty.call(message, "latest"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.latest);
            return writer;
        };

        /**
         * Encodes the specified UpgradeApiVersion message, length delimited. Does not implicitly {@link socket_api.UpgradeApiVersion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion} message UpgradeApiVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpgradeApiVersion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpgradeApiVersion message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpgradeApiVersion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.UpgradeApiVersion();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.latest = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpgradeApiVersion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpgradeApiVersion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpgradeApiVersion message.
         * @function verify
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpgradeApiVersion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.latest != null && message.hasOwnProperty("latest"))
                if (!$util.isInteger(message.latest))
                    return "latest: integer expected";
            return null;
        };

        /**
         * Creates an UpgradeApiVersion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         */
        UpgradeApiVersion.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.UpgradeApiVersion)
                return object;
            let message = new $root.socket_api.UpgradeApiVersion();
            if (object.latest != null)
                message.latest = object.latest | 0;
            return message;
        };

        /**
         * Creates a plain object from an UpgradeApiVersion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.UpgradeApiVersion} message UpgradeApiVersion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpgradeApiVersion.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.latest = 0;
            if (message.latest != null && message.hasOwnProperty("latest"))
                object.latest = message.latest;
            return object;
        };

        /**
         * Converts this UpgradeApiVersion to JSON.
         * @function toJSON
         * @memberof socket_api.UpgradeApiVersion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpgradeApiVersion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpgradeApiVersion
         * @function getTypeUrl
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpgradeApiVersion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/socket_api.UpgradeApiVersion";
        };

        return UpgradeApiVersion;
    })();

    return socket_api;
})();

export const authentication = $root.authentication = (() => {

    /**
     * Namespace authentication.
     * @exports authentication
     * @namespace
     */
    const authentication = {};

    authentication.LoginToken = (function() {

        /**
         * Properties of a LoginToken.
         * @memberof authentication
         * @interface ILoginToken
         * @property {string|null} [token] LoginToken token
         */

        /**
         * Constructs a new LoginToken.
         * @memberof authentication
         * @classdesc Represents a LoginToken.
         * @implements ILoginToken
         * @constructor
         * @param {authentication.ILoginToken=} [properties] Properties to set
         */
        function LoginToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginToken token.
         * @member {string} token
         * @memberof authentication.LoginToken
         * @instance
         */
        LoginToken.prototype.token = "";

        /**
         * Creates a new LoginToken instance using the specified properties.
         * @function create
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken=} [properties] Properties to set
         * @returns {authentication.LoginToken} LoginToken instance
         */
        LoginToken.create = function create(properties) {
            return new LoginToken(properties);
        };

        /**
         * Encodes the specified LoginToken message. Does not implicitly {@link authentication.LoginToken.verify|verify} messages.
         * @function encode
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken} message LoginToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified LoginToken message, length delimited. Does not implicitly {@link authentication.LoginToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken} message LoginToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginToken message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.LoginToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.LoginToken} LoginToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.LoginToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.token = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.LoginToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.LoginToken} LoginToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginToken message.
         * @function verify
         * @memberof authentication.LoginToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates a LoginToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.LoginToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.LoginToken} LoginToken
         */
        LoginToken.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.LoginToken)
                return object;
            let message = new $root.authentication.LoginToken();
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from a LoginToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.LoginToken} message LoginToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.token = "";
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this LoginToken to JSON.
         * @function toJSON
         * @memberof authentication.LoginToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginToken
         * @function getTypeUrl
         * @memberof authentication.LoginToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.LoginToken";
        };

        return LoginToken;
    })();

    authentication.LoginError = (function() {

        /**
         * Properties of a LoginError.
         * @memberof authentication
         * @interface ILoginError
         * @property {string|null} [errorText] LoginError errorText
         * @property {string|null} [errorCode] LoginError errorCode
         */

        /**
         * Constructs a new LoginError.
         * @memberof authentication
         * @classdesc Represents a LoginError.
         * @implements ILoginError
         * @constructor
         * @param {authentication.ILoginError=} [properties] Properties to set
         */
        function LoginError(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginError errorText.
         * @member {string} errorText
         * @memberof authentication.LoginError
         * @instance
         */
        LoginError.prototype.errorText = "";

        /**
         * LoginError errorCode.
         * @member {string} errorCode
         * @memberof authentication.LoginError
         * @instance
         */
        LoginError.prototype.errorCode = "";

        /**
         * Creates a new LoginError instance using the specified properties.
         * @function create
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError=} [properties] Properties to set
         * @returns {authentication.LoginError} LoginError instance
         */
        LoginError.create = function create(properties) {
            return new LoginError(properties);
        };

        /**
         * Encodes the specified LoginError message. Does not implicitly {@link authentication.LoginError.verify|verify} messages.
         * @function encode
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError} message LoginError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.errorText != null && Object.hasOwnProperty.call(message, "errorText"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.errorText);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified LoginError message, length delimited. Does not implicitly {@link authentication.LoginError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError} message LoginError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginError message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.LoginError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.LoginError} LoginError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.LoginError();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.errorText = reader.string();
                        break;
                    }
                case 2: {
                        message.errorCode = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.LoginError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.LoginError} LoginError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginError message.
         * @function verify
         * @memberof authentication.LoginError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.errorText != null && message.hasOwnProperty("errorText"))
                if (!$util.isString(message.errorText))
                    return "errorText: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isString(message.errorCode))
                    return "errorCode: string expected";
            return null;
        };

        /**
         * Creates a LoginError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.LoginError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.LoginError} LoginError
         */
        LoginError.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.LoginError)
                return object;
            let message = new $root.authentication.LoginError();
            if (object.errorText != null)
                message.errorText = String(object.errorText);
            if (object.errorCode != null)
                message.errorCode = String(object.errorCode);
            return message;
        };

        /**
         * Creates a plain object from a LoginError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.LoginError} message LoginError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.errorText = "";
                object.errorCode = "";
            }
            if (message.errorText != null && message.hasOwnProperty("errorText"))
                object.errorText = message.errorText;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this LoginError to JSON.
         * @function toJSON
         * @memberof authentication.LoginError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginError
         * @function getTypeUrl
         * @memberof authentication.LoginError
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.LoginError";
        };

        return LoginError;
    })();

    authentication.Login = (function() {

        /**
         * Properties of a Login.
         * @memberof authentication
         * @interface ILogin
         * @property {string|null} [username] Login username
         * @property {string|null} [password] Login password
         */

        /**
         * Constructs a new Login.
         * @memberof authentication
         * @classdesc Represents a Login.
         * @implements ILogin
         * @constructor
         * @param {authentication.ILogin=} [properties] Properties to set
         */
        function Login(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Login username.
         * @member {string} username
         * @memberof authentication.Login
         * @instance
         */
        Login.prototype.username = "";

        /**
         * Login password.
         * @member {string} password
         * @memberof authentication.Login
         * @instance
         */
        Login.prototype.password = "";

        /**
         * Creates a new Login instance using the specified properties.
         * @function create
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin=} [properties] Properties to set
         * @returns {authentication.Login} Login instance
         */
        Login.create = function create(properties) {
            return new Login(properties);
        };

        /**
         * Encodes the specified Login message. Does not implicitly {@link authentication.Login.verify|verify} messages.
         * @function encode
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin} message Login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Login.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.username);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };

        /**
         * Encodes the specified Login message, length delimited. Does not implicitly {@link authentication.Login.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin} message Login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Login.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Login message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.Login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.Login} Login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Login.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.Login();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.username = reader.string();
                        break;
                    }
                case 2: {
                        message.password = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Login message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.Login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.Login} Login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Login.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Login message.
         * @function verify
         * @memberof authentication.Login
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Login.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.username != null && message.hasOwnProperty("username"))
                if (!$util.isString(message.username))
                    return "username: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };

        /**
         * Creates a Login message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.Login
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.Login} Login
         */
        Login.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.Login)
                return object;
            let message = new $root.authentication.Login();
            if (object.username != null)
                message.username = String(object.username);
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        /**
         * Creates a plain object from a Login message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.Login
         * @static
         * @param {authentication.Login} message Login
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Login.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.username = "";
                object.password = "";
            }
            if (message.username != null && message.hasOwnProperty("username"))
                object.username = message.username;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };

        /**
         * Converts this Login to JSON.
         * @function toJSON
         * @memberof authentication.Login
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Login.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Login
         * @function getTypeUrl
         * @memberof authentication.Login
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Login.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.Login";
        };

        return Login;
    })();

    authentication.VerifyToken = (function() {

        /**
         * Properties of a VerifyToken.
         * @memberof authentication
         * @interface IVerifyToken
         */

        /**
         * Constructs a new VerifyToken.
         * @memberof authentication
         * @classdesc Represents a VerifyToken.
         * @implements IVerifyToken
         * @constructor
         * @param {authentication.IVerifyToken=} [properties] Properties to set
         */
        function VerifyToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new VerifyToken instance using the specified properties.
         * @function create
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken=} [properties] Properties to set
         * @returns {authentication.VerifyToken} VerifyToken instance
         */
        VerifyToken.create = function create(properties) {
            return new VerifyToken(properties);
        };

        /**
         * Encodes the specified VerifyToken message. Does not implicitly {@link authentication.VerifyToken.verify|verify} messages.
         * @function encode
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken} message VerifyToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified VerifyToken message, length delimited. Does not implicitly {@link authentication.VerifyToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken} message VerifyToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VerifyToken message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.VerifyToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.VerifyToken} VerifyToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.VerifyToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VerifyToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.VerifyToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.VerifyToken} VerifyToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VerifyToken message.
         * @function verify
         * @memberof authentication.VerifyToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VerifyToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a VerifyToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.VerifyToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.VerifyToken} VerifyToken
         */
        VerifyToken.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.VerifyToken)
                return object;
            return new $root.authentication.VerifyToken();
        };

        /**
         * Creates a plain object from a VerifyToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.VerifyToken} message VerifyToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VerifyToken.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this VerifyToken to JSON.
         * @function toJSON
         * @memberof authentication.VerifyToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VerifyToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VerifyToken
         * @function getTypeUrl
         * @memberof authentication.VerifyToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VerifyToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.VerifyToken";
        };

        return VerifyToken;
    })();

    authentication.TokenInvalid = (function() {

        /**
         * Properties of a TokenInvalid.
         * @memberof authentication
         * @interface ITokenInvalid
         */

        /**
         * Constructs a new TokenInvalid.
         * @memberof authentication
         * @classdesc Represents a TokenInvalid.
         * @implements ITokenInvalid
         * @constructor
         * @param {authentication.ITokenInvalid=} [properties] Properties to set
         */
        function TokenInvalid(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new TokenInvalid instance using the specified properties.
         * @function create
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid=} [properties] Properties to set
         * @returns {authentication.TokenInvalid} TokenInvalid instance
         */
        TokenInvalid.create = function create(properties) {
            return new TokenInvalid(properties);
        };

        /**
         * Encodes the specified TokenInvalid message. Does not implicitly {@link authentication.TokenInvalid.verify|verify} messages.
         * @function encode
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid} message TokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenInvalid.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified TokenInvalid message, length delimited. Does not implicitly {@link authentication.TokenInvalid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid} message TokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenInvalid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TokenInvalid message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.TokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.TokenInvalid} TokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenInvalid.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.TokenInvalid();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TokenInvalid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.TokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.TokenInvalid} TokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenInvalid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TokenInvalid message.
         * @function verify
         * @memberof authentication.TokenInvalid
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TokenInvalid.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a TokenInvalid message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.TokenInvalid
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.TokenInvalid} TokenInvalid
         */
        TokenInvalid.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.TokenInvalid)
                return object;
            return new $root.authentication.TokenInvalid();
        };

        /**
         * Creates a plain object from a TokenInvalid message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.TokenInvalid} message TokenInvalid
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TokenInvalid.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this TokenInvalid to JSON.
         * @function toJSON
         * @memberof authentication.TokenInvalid
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TokenInvalid.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TokenInvalid
         * @function getTypeUrl
         * @memberof authentication.TokenInvalid
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TokenInvalid.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.TokenInvalid";
        };

        return TokenInvalid;
    })();

    return authentication;
})();

export const uploader = $root.uploader = (() => {

    /**
     * Namespace uploader.
     * @exports uploader
     * @namespace
     */
    const uploader = {};

    uploader.UploadTask = (function() {

        /**
         * Properties of an UploadTask.
         * @memberof uploader
         * @interface IUploadTask
         * @property {number|Long|null} [created] UploadTask created
         * @property {string|null} [path] UploadTask path
         * @property {string|null} [name] UploadTask name
         * @property {string|null} [mime] UploadTask mime
         * @property {uploader.UploadStatus|null} [status] UploadTask status
         * @property {number|null} [retryCounter] UploadTask retryCounter
         * @property {string|null} [error] UploadTask error
         * @property {string|null} [fingerprint] UploadTask fingerprint
         * @property {string|null} [url] UploadTask url
         * @property {Object.<string,string>|null} [metadata] UploadTask metadata
         */

        /**
         * Constructs a new UploadTask.
         * @memberof uploader
         * @classdesc Represents an UploadTask.
         * @implements IUploadTask
         * @constructor
         * @param {uploader.IUploadTask=} [properties] Properties to set
         */
        function UploadTask(properties) {
            this.metadata = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadTask created.
         * @member {number|Long} created
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UploadTask path.
         * @member {string} path
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.path = "";

        /**
         * UploadTask name.
         * @member {string} name
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.name = "";

        /**
         * UploadTask mime.
         * @member {string} mime
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.mime = "";

        /**
         * UploadTask status.
         * @member {uploader.UploadStatus} status
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.status = 0;

        /**
         * UploadTask retryCounter.
         * @member {number} retryCounter
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.retryCounter = 0;

        /**
         * UploadTask error.
         * @member {string} error
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.error = "";

        /**
         * UploadTask fingerprint.
         * @member {string} fingerprint
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.fingerprint = "";

        /**
         * UploadTask url.
         * @member {string} url
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.url = "";

        /**
         * UploadTask metadata.
         * @member {Object.<string,string>} metadata
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.metadata = $util.emptyObject;

        /**
         * Creates a new UploadTask instance using the specified properties.
         * @function create
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask=} [properties] Properties to set
         * @returns {uploader.UploadTask} UploadTask instance
         */
        UploadTask.create = function create(properties) {
            return new UploadTask(properties);
        };

        /**
         * Encodes the specified UploadTask message. Does not implicitly {@link uploader.UploadTask.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask} message UploadTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadTask.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.created);
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.status);
            if (message.retryCounter != null && Object.hasOwnProperty.call(message, "retryCounter"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.retryCounter);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.error);
            if (message.fingerprint != null && Object.hasOwnProperty.call(message, "fingerprint"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.fingerprint);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.url);
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                for (let keys = Object.keys(message.metadata), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 10, wireType 2 =*/82).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.metadata[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadTask message, length delimited. Does not implicitly {@link uploader.UploadTask.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask} message UploadTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadTask.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadTask message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadTask} UploadTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadTask.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadTask(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.created = reader.uint64();
                        break;
                    }
                case 2: {
                        message.path = reader.string();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.mime = reader.string();
                        break;
                    }
                case 5: {
                        message.status = reader.int32();
                        break;
                    }
                case 6: {
                        message.retryCounter = reader.int32();
                        break;
                    }
                case 7: {
                        message.error = reader.string();
                        break;
                    }
                case 8: {
                        message.fingerprint = reader.string();
                        break;
                    }
                case 9: {
                        message.url = reader.string();
                        break;
                    }
                case 10: {
                        if (message.metadata === $util.emptyObject)
                            message.metadata = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.metadata[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadTask message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadTask} UploadTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadTask.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadTask message.
         * @function verify
         * @memberof uploader.UploadTask
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadTask.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.created != null && message.hasOwnProperty("created"))
                if (!$util.isInteger(message.created) && !(message.created && $util.isInteger(message.created.low) && $util.isInteger(message.created.high)))
                    return "created: integer|Long expected";
            if (message.path != null && message.hasOwnProperty("path"))
                if (!$util.isString(message.path))
                    return "path: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.retryCounter != null && message.hasOwnProperty("retryCounter"))
                if (!$util.isInteger(message.retryCounter))
                    return "retryCounter: integer expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            if (message.fingerprint != null && message.hasOwnProperty("fingerprint"))
                if (!$util.isString(message.fingerprint))
                    return "fingerprint: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                if (!$util.isObject(message.metadata))
                    return "metadata: object expected";
                let key = Object.keys(message.metadata);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.metadata[key[i]]))
                        return "metadata: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an UploadTask message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadTask
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadTask} UploadTask
         */
        UploadTask.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadTask)
                return object;
            let message = new $root.uploader.UploadTask();
            if (object.created != null)
                if ($util.Long)
                    (message.created = $util.Long.fromValue(object.created)).unsigned = true;
                else if (typeof object.created === "string")
                    message.created = parseInt(object.created, 10);
                else if (typeof object.created === "number")
                    message.created = object.created;
                else if (typeof object.created === "object")
                    message.created = new $util.LongBits(object.created.low >>> 0, object.created.high >>> 0).toNumber(true);
            if (object.path != null)
                message.path = String(object.path);
            if (object.name != null)
                message.name = String(object.name);
            if (object.mime != null)
                message.mime = String(object.mime);
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "scheduled":
            case 0:
                message.status = 0;
                break;
            case "uploading":
            case 1:
                message.status = 1;
                break;
            case "paused":
            case 3:
                message.status = 3;
                break;
            case "done":
            case 4:
                message.status = 4;
                break;
            case "error":
            case 5:
                message.status = 5;
                break;
            case "restored":
            case 6:
                message.status = 6;
                break;
            }
            if (object.retryCounter != null)
                message.retryCounter = object.retryCounter | 0;
            if (object.error != null)
                message.error = String(object.error);
            if (object.fingerprint != null)
                message.fingerprint = String(object.fingerprint);
            if (object.url != null)
                message.url = String(object.url);
            if (object.metadata) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".uploader.UploadTask.metadata: object expected");
                message.metadata = {};
                for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i)
                    message.metadata[keys[i]] = String(object.metadata[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadTask message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.UploadTask} message UploadTask
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadTask.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.metadata = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.created = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.created = options.longs === String ? "0" : 0;
                object.path = "";
                object.name = "";
                object.mime = "";
                object.status = options.enums === String ? "scheduled" : 0;
                object.retryCounter = 0;
                object.error = "";
                object.fingerprint = "";
                object.url = "";
            }
            if (message.created != null && message.hasOwnProperty("created"))
                if (typeof message.created === "number")
                    object.created = options.longs === String ? String(message.created) : message.created;
                else
                    object.created = options.longs === String ? $util.Long.prototype.toString.call(message.created) : options.longs === Number ? new $util.LongBits(message.created.low >>> 0, message.created.high >>> 0).toNumber(true) : message.created;
            if (message.path != null && message.hasOwnProperty("path"))
                object.path = message.path;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.uploader.UploadStatus[message.status] === undefined ? message.status : $root.uploader.UploadStatus[message.status] : message.status;
            if (message.retryCounter != null && message.hasOwnProperty("retryCounter"))
                object.retryCounter = message.retryCounter;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.fingerprint != null && message.hasOwnProperty("fingerprint"))
                object.fingerprint = message.fingerprint;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            let keys2;
            if (message.metadata && (keys2 = Object.keys(message.metadata)).length) {
                object.metadata = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.metadata[keys2[j]] = message.metadata[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this UploadTask to JSON.
         * @function toJSON
         * @memberof uploader.UploadTask
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadTask.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadTask
         * @function getTypeUrl
         * @memberof uploader.UploadTask
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadTask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.UploadTask";
        };

        return UploadTask;
    })();

    /**
     * UploadStatus enum.
     * @name uploader.UploadStatus
     * @enum {number}
     * @property {number} scheduled=0 scheduled value
     * @property {number} uploading=1 uploading value
     * @property {number} paused=3 paused value
     * @property {number} done=4 done value
     * @property {number} error=5 error value
     * @property {number} restored=6 restored value
     */
    uploader.UploadStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "scheduled"] = 0;
        values[valuesById[1] = "uploading"] = 1;
        values[valuesById[3] = "paused"] = 3;
        values[valuesById[4] = "done"] = 4;
        values[valuesById[5] = "error"] = 5;
        values[valuesById[6] = "restored"] = 6;
        return values;
    })();

    uploader.UploadUFile = (function() {

        /**
         * Properties of an UploadUFile.
         * @memberof uploader
         * @interface IUploadUFile
         * @property {string|null} [fileId] UploadUFile fileId
         * @property {uploader.IUploadTask|null} [task] UploadUFile task
         */

        /**
         * Constructs a new UploadUFile.
         * @memberof uploader
         * @classdesc Represents an UploadUFile.
         * @implements IUploadUFile
         * @constructor
         * @param {uploader.IUploadUFile=} [properties] Properties to set
         */
        function UploadUFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadUFile fileId.
         * @member {string} fileId
         * @memberof uploader.UploadUFile
         * @instance
         */
        UploadUFile.prototype.fileId = "";

        /**
         * UploadUFile task.
         * @member {uploader.IUploadTask|null|undefined} task
         * @memberof uploader.UploadUFile
         * @instance
         */
        UploadUFile.prototype.task = null;

        /**
         * Creates a new UploadUFile instance using the specified properties.
         * @function create
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile=} [properties] Properties to set
         * @returns {uploader.UploadUFile} UploadUFile instance
         */
        UploadUFile.create = function create(properties) {
            return new UploadUFile(properties);
        };

        /**
         * Encodes the specified UploadUFile message. Does not implicitly {@link uploader.UploadUFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile} message UploadUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadUFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                $root.uploader.UploadTask.encode(message.task, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadUFile message, length delimited. Does not implicitly {@link uploader.UploadUFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile} message UploadUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadUFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadUFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadUFile} UploadUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadUFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadUFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fileId = reader.string();
                        break;
                    }
                case 2: {
                        message.task = $root.uploader.UploadTask.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadUFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadUFile} UploadUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadUFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadUFile message.
         * @function verify
         * @memberof uploader.UploadUFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadUFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            if (message.task != null && message.hasOwnProperty("task")) {
                let error = $root.uploader.UploadTask.verify(message.task);
                if (error)
                    return "task." + error;
            }
            return null;
        };

        /**
         * Creates an UploadUFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadUFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadUFile} UploadUFile
         */
        UploadUFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadUFile)
                return object;
            let message = new $root.uploader.UploadUFile();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            if (object.task != null) {
                if (typeof object.task !== "object")
                    throw TypeError(".uploader.UploadUFile.task: object expected");
                message.task = $root.uploader.UploadTask.fromObject(object.task);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadUFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.UploadUFile} message UploadUFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadUFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.fileId = "";
                object.task = null;
            }
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.task != null && message.hasOwnProperty("task"))
                object.task = $root.uploader.UploadTask.toObject(message.task, options);
            return object;
        };

        /**
         * Converts this UploadUFile to JSON.
         * @function toJSON
         * @memberof uploader.UploadUFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadUFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadUFile
         * @function getTypeUrl
         * @memberof uploader.UploadUFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadUFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.UploadUFile";
        };

        return UploadUFile;
    })();

    uploader.DeleteUFile = (function() {

        /**
         * Properties of a DeleteUFile.
         * @memberof uploader
         * @interface IDeleteUFile
         * @property {string|null} [id] DeleteUFile id
         */

        /**
         * Constructs a new DeleteUFile.
         * @memberof uploader
         * @classdesc Represents a DeleteUFile.
         * @implements IDeleteUFile
         * @constructor
         * @param {uploader.IDeleteUFile=} [properties] Properties to set
         */
        function DeleteUFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteUFile id.
         * @member {string} id
         * @memberof uploader.DeleteUFile
         * @instance
         */
        DeleteUFile.prototype.id = "";

        /**
         * Creates a new DeleteUFile instance using the specified properties.
         * @function create
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile=} [properties] Properties to set
         * @returns {uploader.DeleteUFile} DeleteUFile instance
         */
        DeleteUFile.create = function create(properties) {
            return new DeleteUFile(properties);
        };

        /**
         * Encodes the specified DeleteUFile message. Does not implicitly {@link uploader.DeleteUFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile} message DeleteUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified DeleteUFile message, length delimited. Does not implicitly {@link uploader.DeleteUFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile} message DeleteUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteUFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.DeleteUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.DeleteUFile} DeleteUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.DeleteUFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteUFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.DeleteUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.DeleteUFile} DeleteUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteUFile message.
         * @function verify
         * @memberof uploader.DeleteUFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteUFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a DeleteUFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.DeleteUFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.DeleteUFile} DeleteUFile
         */
        DeleteUFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.DeleteUFile)
                return object;
            let message = new $root.uploader.DeleteUFile();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a DeleteUFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.DeleteUFile} message DeleteUFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteUFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this DeleteUFile to JSON.
         * @function toJSON
         * @memberof uploader.DeleteUFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteUFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteUFile
         * @function getTypeUrl
         * @memberof uploader.DeleteUFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteUFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.DeleteUFile";
        };

        return DeleteUFile;
    })();

    uploader.UploadSlot = (function() {

        /**
         * Properties of an UploadSlot.
         * @memberof uploader
         * @interface IUploadSlot
         * @property {uploader.IUFile|null} [file] UploadSlot file
         * @property {Object.<string,string>|null} [metadata] UploadSlot metadata
         */

        /**
         * Constructs a new UploadSlot.
         * @memberof uploader
         * @classdesc Represents an UploadSlot.
         * @implements IUploadSlot
         * @constructor
         * @param {uploader.IUploadSlot=} [properties] Properties to set
         */
        function UploadSlot(properties) {
            this.metadata = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadSlot file.
         * @member {uploader.IUFile|null|undefined} file
         * @memberof uploader.UploadSlot
         * @instance
         */
        UploadSlot.prototype.file = null;

        /**
         * UploadSlot metadata.
         * @member {Object.<string,string>} metadata
         * @memberof uploader.UploadSlot
         * @instance
         */
        UploadSlot.prototype.metadata = $util.emptyObject;

        /**
         * Creates a new UploadSlot instance using the specified properties.
         * @function create
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot=} [properties] Properties to set
         * @returns {uploader.UploadSlot} UploadSlot instance
         */
        UploadSlot.create = function create(properties) {
            return new UploadSlot(properties);
        };

        /**
         * Encodes the specified UploadSlot message. Does not implicitly {@link uploader.UploadSlot.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot} message UploadSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.uploader.UFile.encode(message.file, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                for (let keys = Object.keys(message.metadata), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.metadata[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadSlot message, length delimited. Does not implicitly {@link uploader.UploadSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot} message UploadSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadSlot message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadSlot} UploadSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadSlot(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.file = $root.uploader.UFile.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (message.metadata === $util.emptyObject)
                            message.metadata = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.metadata[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadSlot} UploadSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadSlot message.
         * @function verify
         * @memberof uploader.UploadSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.uploader.UFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                if (!$util.isObject(message.metadata))
                    return "metadata: object expected";
                let key = Object.keys(message.metadata);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.metadata[key[i]]))
                        return "metadata: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an UploadSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadSlot} UploadSlot
         */
        UploadSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadSlot)
                return object;
            let message = new $root.uploader.UploadSlot();
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".uploader.UploadSlot.file: object expected");
                message.file = $root.uploader.UFile.fromObject(object.file);
            }
            if (object.metadata) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".uploader.UploadSlot.metadata: object expected");
                message.metadata = {};
                for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i)
                    message.metadata[keys[i]] = String(object.metadata[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.UploadSlot} message UploadSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.metadata = {};
            if (options.defaults)
                object.file = null;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.uploader.UFile.toObject(message.file, options);
            let keys2;
            if (message.metadata && (keys2 = Object.keys(message.metadata)).length) {
                object.metadata = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.metadata[keys2[j]] = message.metadata[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this UploadSlot to JSON.
         * @function toJSON
         * @memberof uploader.UploadSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadSlot
         * @function getTypeUrl
         * @memberof uploader.UploadSlot
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadSlot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.UploadSlot";
        };

        return UploadSlot;
    })();

    uploader.UFile = (function() {

        /**
         * Properties of a UFile.
         * @memberof uploader
         * @interface IUFile
         * @property {string|null} [id] UFile id
         * @property {string|null} [src] UFile src
         * @property {string|null} [preview] UFile preview
         * @property {string|null} [previewLarge] UFile previewLarge
         * @property {string|null} [name] UFile name
         * @property {string|null} [uploadTask] UFile uploadTask
         * @property {string|null} [localPath] UFile localPath
         */

        /**
         * Constructs a new UFile.
         * @memberof uploader
         * @classdesc Represents a UFile.
         * @implements IUFile
         * @constructor
         * @param {uploader.IUFile=} [properties] Properties to set
         */
        function UFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UFile id.
         * @member {string} id
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.id = "";

        /**
         * UFile src.
         * @member {string} src
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.src = "";

        /**
         * UFile preview.
         * @member {string} preview
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.preview = "";

        /**
         * UFile previewLarge.
         * @member {string} previewLarge
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.previewLarge = "";

        /**
         * UFile name.
         * @member {string} name
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.name = "";

        /**
         * UFile uploadTask.
         * @member {string} uploadTask
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.uploadTask = "";

        /**
         * UFile localPath.
         * @member {string} localPath
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.localPath = "";

        /**
         * Creates a new UFile instance using the specified properties.
         * @function create
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile=} [properties] Properties to set
         * @returns {uploader.UFile} UFile instance
         */
        UFile.create = function create(properties) {
            return new UFile(properties);
        };

        /**
         * Encodes the specified UFile message. Does not implicitly {@link uploader.UFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile} message UFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.src != null && Object.hasOwnProperty.call(message, "src"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.src);
            if (message.preview != null && Object.hasOwnProperty.call(message, "preview"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.preview);
            if (message.previewLarge != null && Object.hasOwnProperty.call(message, "previewLarge"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.previewLarge);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
            if (message.uploadTask != null && Object.hasOwnProperty.call(message, "uploadTask"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.uploadTask);
            if (message.localPath != null && Object.hasOwnProperty.call(message, "localPath"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.localPath);
            return writer;
        };

        /**
         * Encodes the specified UFile message, length delimited. Does not implicitly {@link uploader.UFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile} message UFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UFile} UFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.src = reader.string();
                        break;
                    }
                case 3: {
                        message.preview = reader.string();
                        break;
                    }
                case 4: {
                        message.previewLarge = reader.string();
                        break;
                    }
                case 5: {
                        message.name = reader.string();
                        break;
                    }
                case 6: {
                        message.uploadTask = reader.string();
                        break;
                    }
                case 7: {
                        message.localPath = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UFile} UFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UFile message.
         * @function verify
         * @memberof uploader.UFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.src != null && message.hasOwnProperty("src"))
                if (!$util.isString(message.src))
                    return "src: string expected";
            if (message.preview != null && message.hasOwnProperty("preview"))
                if (!$util.isString(message.preview))
                    return "preview: string expected";
            if (message.previewLarge != null && message.hasOwnProperty("previewLarge"))
                if (!$util.isString(message.previewLarge))
                    return "previewLarge: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.uploadTask != null && message.hasOwnProperty("uploadTask"))
                if (!$util.isString(message.uploadTask))
                    return "uploadTask: string expected";
            if (message.localPath != null && message.hasOwnProperty("localPath"))
                if (!$util.isString(message.localPath))
                    return "localPath: string expected";
            return null;
        };

        /**
         * Creates a UFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UFile} UFile
         */
        UFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UFile)
                return object;
            let message = new $root.uploader.UFile();
            if (object.id != null)
                message.id = String(object.id);
            if (object.src != null)
                message.src = String(object.src);
            if (object.preview != null)
                message.preview = String(object.preview);
            if (object.previewLarge != null)
                message.previewLarge = String(object.previewLarge);
            if (object.name != null)
                message.name = String(object.name);
            if (object.uploadTask != null)
                message.uploadTask = String(object.uploadTask);
            if (object.localPath != null)
                message.localPath = String(object.localPath);
            return message;
        };

        /**
         * Creates a plain object from a UFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UFile
         * @static
         * @param {uploader.UFile} message UFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.src = "";
                object.preview = "";
                object.previewLarge = "";
                object.name = "";
                object.uploadTask = "";
                object.localPath = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.src != null && message.hasOwnProperty("src"))
                object.src = message.src;
            if (message.preview != null && message.hasOwnProperty("preview"))
                object.preview = message.preview;
            if (message.previewLarge != null && message.hasOwnProperty("previewLarge"))
                object.previewLarge = message.previewLarge;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.uploadTask != null && message.hasOwnProperty("uploadTask"))
                object.uploadTask = message.uploadTask;
            if (message.localPath != null && message.hasOwnProperty("localPath"))
                object.localPath = message.localPath;
            return object;
        };

        /**
         * Converts this UFile to JSON.
         * @function toJSON
         * @memberof uploader.UFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UFile
         * @function getTypeUrl
         * @memberof uploader.UFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.UFile";
        };

        return UFile;
    })();

    return uploader;
})();

export { $root as default };
