import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {Theme} from "../theme";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {ApiContext, LppStoreContext} from "../App";
import {useContext, useEffect, useState} from "react";
import {OverlayContainer} from "./overlays";
import {DialogContainer} from "./dialogs";

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

export const LppScaffold = observer(({
                                         children,
                                         waitConnected
                                     }: { children: any, waitConnected?: boolean }) => {
    const api = useContext(ApiContext);
    const [connected, setConnected] = useState(api.connection.connected);
    useEffect(() => {
        if (waitConnected)
            api.connection.whenConnected.then(() => setConnected(true));
    }, [api, waitConnected]);
    const store = useContext(LppStoreContext);

    return <Container>
        {(!waitConnected || connected) && children}
        <ConnectedOverlay/>
        {store.dialogs.map((e) => e)}
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </Container>
});

export const ConnectedOverlay = () => {
    const api = useContext(ApiContext);
    const theme = useTheme() as Theme;

    // handle connected overlay
    const [connected, setConnected] = useState(true);
    useEffect(() => {
        const sub = api.connection.connectedChanges.subscribe((connected) => setConnected(connected));
        const timeout = new Promise((res) => setTimeout(() => res(null), 1000));
        Promise.race([api.connection.whenConnected, timeout]).then(
            (_) => setConnected(api.connection.connected)
        );
        return () => sub.unsubscribe();
    }, [api.connection]);

    return <div style={{
        position: 'fixed',
        bottom: '0',
        color: 'white',
        fontWeight: 'bold',
        left: '0',
        right: '0',
        backgroundColor: 'red',
        height: '20px',
        display: 'flex',
        opacity: connected ? '0' : '1',
        transition: 'all 500ms',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '1000',
    }}>
        Povezava s strežnikom ni uspela!
    </div>;
};