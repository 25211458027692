import {autorun, makeAutoObservable, ObservableMap, ObservableSet} from "mobx";
import {SocketApi} from "proto_socket_typescript";
import {PoiStore} from "./poi_store";
import {lpp_maps} from "../proto/compiled";
import {proto} from "../proto/messages";
import {BusData} from "../data/bus_data";
import {LatLng, LatLngBounds} from "leaflet";
import {LppTypes} from "../lpp_types";

export class LppStore {
    api: SocketApi;
    poi: PoiStore

    layers?: lpp_maps.ILayers;
    layersMap: { [k: string]: lpp_maps.ILayer } = {};
    layerData: ObservableMap<string, lpp_maps.ILayer> = new ObservableMap();
    visibleLayers: ObservableSet<string> = new ObservableSet();
    showBusses = true;
    busses: BusData[] = [];
    isMobile = window.innerWidth < 1000;
    dialogs: any[] = [];
    markerUpdates = new ObservableMap<string, lpp_maps.IMarkerLayer>();
    mapBounds = new LatLngBounds([45.104380, 12.761887], [47.249232, 16.854669]);
    currentPosition?: LatLng;
    private groupColors: { [k: string]: string } = {};

    constructor(api: SocketApi) {
        this.api = api;
        this.poi = new PoiStore(api);
        makeAutoObservable(this);

        api.getMessageHandler(new proto.RxLayers()).subscribe((m) => this.onLayers(m));
        api.getMessageHandler(new proto.RxLayerData()).subscribe((m) => this.onLayer(m));
        api.getMessageHandler(new proto.RxMarkerLayer()).subscribe((m) => this.onMarkerLayer(m));
        setInterval(() => this.updateBusses(), 8000);

        api.connection.whenConnected.then(() => {
            api.sendMessage(proto.TxLoadLayers.create());
        });

        if (navigator.geolocation) {
            this.locationUpdates();
        }
    }

    private onLayers(message: proto.RxLayers) {
        this.layers = message.proto;
        this.layers?.layers?.forEach((l) => {
            this.groupColors[l.shortLabel!] = LppTypes.Color(l.color!);
            this.layersMap[l.id!] = l;
        });
        this.layers.layers?.sort((a, b) => {
            return parseInt(a.shortLabel!) - parseInt(b.shortLabel!);
        });
    }

    private onLayer(message: proto.RxLayerData) {
        message.proto.layers.forEach((l) => this.layerData.set(l!.id!, l!));
    }


    toggleLayer(layer: lpp_maps.ILayer) {
        if (this.visibleLayers.has(layer.id!)) {
            this.visibleLayers.delete(layer.id!);
        } else {
            if (!this.layerData.has(layer.id!)) {
                this.api.sendMessage(proto.TxLoadLayer.create({
                    ids: [layer.id!]
                }));
            }
            this.visibleLayers.add(layer.id!);
        }
        this.updateBusses();
    }

    markerWithUpdates(marker: lpp_maps.IMarkerLayer) {
        return this.markerUpdates.get(marker.id!) ?? marker;
    }

    private onMarkerLayer(m: proto.RxMarkerLayer) {
        this.markerUpdates.set(m.proto.id!, m.proto);
    }

    private updateBussesTimeout?: NodeJS.Timeout;

    updateBusses() {
        if (this.updateBussesTimeout) {
            this.updateBussesTimeout.refresh();
        } else {
            this.updateBussesTimeout = setTimeout(() => {
                this.updateBussesTimeout = undefined;
                this._updateBusses();
            }, 1000);
        }
    }

    private async _updateBusses() {
        if (!this.showBusses || !this.visibleLayers.size) {
            this.busses = [];
            return;
        }
        const groups: string[] = [];
        this.visibleLayers.forEach((layerId) => {
            const data = this.layersMap[layerId];
            if (data?.type === lpp_maps.LayerType.proga) {
                groups.push(data.shortLabel!);
            }
        });
        if (!groups.length) {
            this.busses = [];
            return;
        }
        let data = await (await fetch(`https://api.ontime.si/api/v1/lpp/buses/?groups=${groups.join(',')}&lat_min=${this.mapBounds?.getSouth()}&lng_min=${this.mapBounds?.getWest()}&lat_max=${this.mapBounds?.getNorth()}&lng_max=${this.mapBounds?.getEast()}`, {
            headers: {
                Authorization: "Api-Key js0EMUU7.93CCcHLN13tkZwgqoAzb1jO4ARv5xGU7",
                "Content-Type": "application/json"
            }
        })).json() as { [k: string]: any };

        if (!this.showBusses || !this.visibleLayers.size) {
            this.busses = [];
        } else {
            this.busses = (data['results'] as { [k: string]: any }[]).map((e) => new BusData(e));
        }
    }

    groupColor(group: string): string {
        return this.groupColors[group] ?? '#333333';
    }

    private locationUpdates() {
        navigator.geolocation.watchPosition((position) => {
            this.currentPosition = new LatLng(position.coords.latitude, position.coords.longitude);
        }, null, {enableHighAccuracy: true});
    }
}
