import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import Logo from '../assets/logo.png';
import {StyledProps, Theme} from "../theme";
import React, {useContext} from "react";
import {MdCheck, MdClear, MdDoneAll, MdSelectAll} from 'react-icons/md'
import {LppStoreContext} from "../App";
import {HashLoader} from "react-spinners";
import {lpp_maps} from "../proto/compiled";
import {LppTypes} from "../lpp_types";
import useWave from "use-wave";
import {IconButton} from "../components/buttons";
import {proto} from "../proto/messages";
import {LoadingOverlay} from "../components/overlays";
import {LayerButton} from "./proge_overlay";

export const ProgeToolbarOverlay = observer(() => {
    const store = useContext(LppStoreContext);
    const wave = useWave();
    return <Container>
        {store.layers && store.layers.layers?.filter((l: any) => l.type === lpp_maps.LayerType.proga)?.map((l: any) =>
            <Button
                ref={wave}
                selected={store.visibleLayers.has(l.id)}
                onClick={() => store.toggleLayer(l)}
                color={LppTypes.Color(l.color)}
                key={l.id}>
                {l.shortLabel}
            </Button>)}
    </Container>;
});

const Container = styled.div`
  backdrop-filter: blur(5px);
  z-index: 500;
  box-shadow: 0.1em 0 0.5em rgba(0, 45, 98, .3);
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  width: 44px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: center;
`;

const Button = styled.div<{ color: string, selected: boolean }>`
  flex-shrink: 0;
  margin: ${({selected}: any) => selected ? '4px 0 4px' : '7px 0 7px'};
  box-shadow: 0 0 0.5em rgba(0, 0, 0, .2);
  background-color: ${({color, selected}: any) => selected ? color : 'rgba(255, 255, 255, 0.5)'};
  width: ${({selected}: any) => selected ? '36px' : '30px'};
  height: ${({selected}: any) => selected ? '36px' : '30px'};
  border-radius: 100px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: ${({color, selected}: any) => selected ? 'white' : color};
  transition: all 300ms;
`;