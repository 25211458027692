import styled, {css} from "styled-components";

export interface Theme {
    primary: string;
    primary10: string;
    neutral: string;
    content: string;
    content50: string;
    content10: string;
    content5: string;
    contentShade: string;
    error: string;
    shade: string;
    idle: string;
    textTheme: TextTheme,
    dimens: Dimens,
}

export interface Dimens {
    contentWidth: string,
}

export interface TextTheme {
    headline1: TextStyle;
    headline2: TextStyle;
    headline3: TextStyle;
    headline4: TextStyle;
    headline5: TextStyle;
    headline6: TextStyle;
    label: TextStyle;
    body: TextStyle;
    error: TextStyle;
    input: TextStyle;
    button: TextStyle;
}

interface TextStyleData {
    size?: string;
    weight?: string;
    height?: string;
    color?: string;
}

export class TextStyle {
    _overrides: { [k: string]: TextStyle } = {};

    _overridesKey(overrides: TextStyleData): string {
        let value = '';
        for (const style of Object.values(overrides)) {
            value += style;
        }
        return value;
    }

    data: TextStyleData;
    span: any;

    constructor(data: TextStyleData) {
        this.data = data;
        this.span = styled.span`
          ${() => this.css}
        `;
    }

    with(overrides: TextStyleData): TextStyle {
        const key = this._overridesKey(overrides);
        if (!this._overrides[key]) {
            this._overrides[key] = new TextStyle(
                {
                    ...this.data,
                    ...overrides
                }
            );
        }
        return this._overrides[key];
    }

    style(theme?: Theme) {
        return {
            color: this.data.color || theme?.content,
            fontSize: this.data.size || theme?.textTheme.body.data.size,
            fontWeight: this.data.weight || theme?.textTheme.body.data.weight,
            lineHeight: this.data.height || theme?.textTheme.body.data.height,
        }
    }

    get css() {
        return ({theme}: { theme: Theme }) => css`
          color: ${this.data.color || theme.content};
          font-size: ${this.data.size || theme.textTheme.body.data.size};
          font-weight: ${this.data.weight || theme.textTheme.body.data.weight};
          line-height: ${this.data.height || theme.textTheme.body.data.height};
        `;
    }
}

abstract class TpColors {
    static contentShade = '#999999';
    static content = '#595959';
    static content50 = 'rgba(89,89,89,0.5)';
    static content10 = 'rgba(89,89,89,0.1)';
    static content5 = 'rgba(89,89,89,0.05)';
    static primary = '#1D7B4A';
    static primary10 = 'rgba(29,123,74,0.1)';
    static neutral = '#FFFFFF';
    static shade = '#E5EAEF';
    static idle = '#EDE4E5';
    static error = 'red';
}

export interface StyledProps {
    theme: Theme;

    [x: string]: any;
}

export const LppTheme: Theme = {
    primary: TpColors.primary,
    primary10: TpColors.primary10,
    neutral: TpColors.neutral,
    content: TpColors.content,
    content50: TpColors.content50,
    content10: TpColors.content10,
    content5: TpColors.content5,
    contentShade: TpColors.contentShade,
    shade: TpColors.shade,
    idle: TpColors.idle,
    error: TpColors.error,
    dimens: {
        contentWidth: '500px',
    },
    textTheme: {
        headline1: new TextStyle({
            size: '60px',
            weight: 'bold',
        }),
        headline2: new TextStyle({
            size: '40px',
            weight: '300',
            color: TpColors.content,
        }),
        headline3: new TextStyle({
            size: '30px',
            weight: '400'
        }),
        headline4: new TextStyle({
            size: '23px',
            weight: '600'
        }),
        headline5: new TextStyle({
            size: '20px',
            weight: '700'
        }),
        headline6: new TextStyle({
            size: '12px',
            weight: 'bold'
        }),
        label: new TextStyle({
            size: '15px',
            weight: '600',
            color: TpColors.contentShade
        }),
        body: new TextStyle({
            size: '13px',
        }),
        error: new TextStyle({
            size: '15px',
            color: 'red'
        }),
        input: new TextStyle({
            size: '24px',
            color: 'black',
            height: '1.2',
        }),
        button: new TextStyle({
            size: '15px',
            weight: 'bold',
            color: TpColors.content,
        })
    },
};
