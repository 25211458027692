import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import Logo from '../assets/logo.png';
import {StyledProps, Theme} from "../theme";
import React, {useContext} from "react";
import {MdCheck, MdClear, MdDoneAll, MdSelectAll} from 'react-icons/md'
import {LppStoreContext} from "../App";
import {HashLoader} from "react-spinners";
import {lpp_maps} from "../proto/compiled";
import {LppTypes} from "../lpp_types";
import useWave from "use-wave";
import {IconButton} from "../components/buttons";
import {proto} from "../proto/messages";
import {LoadingOverlay} from "../components/overlays";

const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 16px;
  bottom: 16px;
  top: 16px;
  z-index: 999;
  pointer-events: none;
`;

const Container1 = styled.div`
  display: flex;
  flex-direction: column;
  height: 65vh;
  width: 300px;
  background-color: white;
  border-radius: 12px;
  padding: 16px;
  overflow: hidden;
  pointer-events: auto;
`;

const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: auto;
  margin-top: 8px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }
`;

const LogoImg = styled.img`
  width: 70%;
  padding-bottom: 12px;
`;

export const ProgeOverlay = observer(() => {
    const theme = useTheme() as Theme;
    const provider = useContext(LppStoreContext);
    const wave = useWave();

    return <Container>
        <LoadingOverlay overlayStyle={{}} builder={(action) => <Container1>
            <LogoImg src={Logo}/>
            <theme.textTheme.headline4.span style={{display: 'flex', alignItems: 'center'}}>
                Linije LPP
                <IconButton style={{marginLeft: 'auto'}} onClick={() => {
                    const load: string[] = [];
                    provider.layers?.layers?.filter((l: any) => l.type === lpp_maps.LayerType.proga).forEach((l) => {
                        if (!provider.layerData.has(l.id!)) {
                            load.push(l.id!);
                        }
                        provider.visibleLayers.add(l.id!);
                    });
                    if (load.length) {
                        action(provider.api.sendMessage(proto.TxLoadLayer.create({
                            ids: load
                        }), {ack: true}));
                    }
                    provider.updateBusses();
                }} ref={wave}><MdDoneAll/></IconButton>
                <IconButton onClick={() => {
                    provider.layers?.layers?.filter((l: any) => l.type === lpp_maps.LayerType.proga).forEach((l) => provider.visibleLayers.delete(l.id));
                    provider.updateBusses();
                }} ref={wave}><MdClear/></IconButton>
            </theme.textTheme.headline4.span>
            <ContentContainer>
                {!provider.layers && <HashLoader color={theme.primary} size={'60px'}/>}
                {provider.layers && provider.layers.layers?.filter((l: any) => l.type === lpp_maps.LayerType.proga)?.map((l: any) =>
                    <LayerButton key={l.id} layer={l}/>)}
            </ContentContainer>
        </Container1>}/>
    </Container>;
});

const LayerContainer = styled.div.attrs({tabIndex: -1})<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  border-radius: 14px;
  cursor: pointer;
  align-items: center;
  border: solid 1px ${({theme, selected}: StyledProps) => selected ? theme.primary10 : theme.content5};
  background-color: ${({theme, selected}: StyledProps) => selected ? theme.primary10 : undefined};
  padding-right: 8px;
  transition: all 300ms;

  .can-hover &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const LayerGroup = styled.div<{ color: string }>`
  background-color: ${({color}) => color};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  width: 25px;
  height: 25px;
  font-size: 10px;
  font-weight: bold;
  color: white;
`;

export const LayerButton = observer(({layer}: { layer: lpp_maps.ILayer }) => {
    const theme = useTheme() as Theme;
    const wave = useWave({
        color: theme.primary,
    });
    const store = useContext(LppStoreContext);
    const selected = store.visibleLayers.has(layer.id);
    return <LayerContainer
        selected={selected}
        ref={wave}
        onClick={() => store.toggleLayer(layer)}>
        <LayerGroup style={{marginRight: '8px'}} color={LppTypes.Color(layer.color!)}>{layer.shortLabel}</LayerGroup>
        <theme.textTheme.headline6.span>{layer.label}</theme.textTheme.headline6.span>
        {selected && <MdCheck style={{marginLeft: 'auto'}} color={theme.primary}/>}
    </LayerContainer>
});