import styled, {useTheme} from "styled-components";
import React, {CSSProperties, useState} from "react";
import {observer} from "mobx-react-lite";
import {Theme} from "../theme";
import {HashLoader} from "react-spinners";

export const OverlayContainer = styled.div<{ hidden?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  z-index: 999;

  opacity: ${({hidden}) => hidden ? 0 : 1};
  pointer-events: ${({hidden}) => hidden ? 'none' : 'auto'};
  transition: opacity 300ms ease;
`;

export const TpLoadingOverlay = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  pointer-events: ${({visible}) => visible ? 'all' : 'none'};
  opacity: ${({visible}) => visible ? '1' : '0'};
  transition: opacity 100ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type LoadingOverlayBuilder<T> = (action: (action: Promise<T>) => Promise<T>) => any;
export const LoadingOverlay = observer(({
                                            builder,
                                            style,
                                            overlayStyle
                                        }: { builder: LoadingOverlayBuilder<any>, overlayStyle?: CSSProperties, style?: CSSProperties }) => {
    const [loading, setLoading] = useState(false);
    const theme = useTheme() as Theme;
    return <div style={{position: 'relative', ...(style ?? {})}}>
        {builder(async (action) => {
            setLoading(true);
            const result = await action;
            setLoading(false);
            return result;
        })}
        <TpLoadingOverlay visible={loading} style={overlayStyle}>
            <HashLoader color={theme.primary}
                        size={'50px'}/>

        </TpLoadingOverlay>
    </div>
})