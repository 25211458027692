import {observer} from "mobx-react-lite";
import {LppFab} from "../components/buttons";
import {MdLayers} from "react-icons/md";
import useWave from "use-wave";
import React, {useContext} from "react";
import {LppStoreContext} from "../App";
import {DialogContainer, LppDialog} from "../components/dialogs";
import {LocationsEntry, LocationsOverlay} from "./locations_overlay";
import {LayerButton, ProgeOverlay} from "./proge_overlay";
import {HashLoader} from "react-spinners";
import {lpp_maps} from "../proto/compiled";
import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "../theme";

export const MobileOverlay = observer(() => {
    const wave = useWave();
    const store = useContext(LppStoreContext);
    return <LppFab
        onClick={() => store.dialogs.push(<Dialog/>)}
        ref={wave}
        style={{
            position: 'fixed',
            bottom: '16px',
            right: '16px',
            zIndex: '900',
        }}>
        <MdLayers/>
    </LppFab>
});

const Dialog = observer(() => {
    const store = useContext(LppStoreContext);
    const theme = useTheme() as Theme;
    return <LppDialog onClose={() => store.dialogs.pop()} style={{backgroundColor: theme.shade, overflow: 'auto'}}>
        <LayersGroupContainer style={{height: 'auto'}}>
            {store.layers && store.layers.layers?.filter((l: lpp_maps.ILayer) => l.type === lpp_maps.LayerType.other)?.map((l: lpp_maps.ILayer) =>
                <LocationsEntry
                    onClick={() => store.toggleLayer(l)}
                    icon={l.legendIcon}
                    label={l.label}
                    key={l.id}
                    start={true}
                    selected={store.visibleLayers.has(l.id)}/>)}
        </LayersGroupContainer>
        <LayersGroupContainer style={{marginBottom: '8px'}}>
            {!store.layers && <HashLoader color={theme.primary} size={'60px'}/>}
            {store.layers && store.layers.layers?.filter((l: any) => l.type === lpp_maps.LayerType.proga)?.map((l: any) =>
                <LayerButton key={l.id} layer={l}/>)}
        </LayersGroupContainer>
    </LppDialog>;
});

const LayersGroupContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  border: solid 1px ${({theme}: StyledProps) => theme.shade};
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin: 8px 8px 0 8px;
`;