import {LatLng} from "leaflet";

export class BusData {
    data: { [k: string]: any };

    constructor(data: { [k: string]: any },) {
        this.data = data;
    }

    get location() {
        return new LatLng(
            this.data['lat'],
            this.data['lng']
        );
    }

    get rotation(): number {
        return this.data['rotation'];
    }

    get group(): string {
        return this.data['bus_group'];
    }

    get registrationPlate(): string {
        return this.data['bus_name'];
    }

    get id(): string {
        return this.data['bus_id'];
    }
}